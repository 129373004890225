import React, { useRef, useState } from "react";
import DialogBox from "./DialogBox";
import { IoSearch } from "react-icons/io5";
import ButtonCompo from "./ButtonCompo";
import { Chip, InputAdornment, TextField } from "@mui/material";
import { axiosInstance, SERVER_URL } from "../constant";
import { IoMdCloseCircle } from "react-icons/io";
import { Link } from "react-router-dom";

function SearchDialogCompo() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [message, setMessage] = useState("Please search something");
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const closeRef = useRef(null);

  async function searchProduct() {
    if (searchQuery?.length <= 1) return null;
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/get/all/1/10?search=${searchQuery}`
      );
      console.log(res.data?.data);
      if (res.data?.data?.data === undefined) {
        setProductData([])
        setMessage("No result found");
      } else {
        setProductData(res.data?.data?.data);
      }
    } catch (error) {
      setMessage("No result found");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    if (closeRef.current) {
      closeRef.current.handleClose();
    }
  };

  return (
    <div>
      <DialogBox
        trigger={
          <section className="bg-white w-[2.4rem] h-[2.4rem] rounded-full flex justify-center items-center cursor-pointer border-[#ff8000] border">
            <IoSearch size={"20px"} className="text-orange-500" />
          </section>
        }
        maxWidth="lg"
        fullScreen
        className="!z-[100000]"
        ref={closeRef}
        title="Search"
      >
        <div className="w-full">
          <TextField
            placeholder="Search Products"
            className="!bg-white !rounded-full w-full"
            onChange={(event) => setSearchQuery(event?.target?.value)}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position={"start"}>
                    <IoSearch className="mr-2 text-slate-500" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <ButtonCompo
                      className="!bg-[#FF8000] !rounded-[23px] !text-[12px] !px-3 !text-white !font-semibold"
                      onClick={searchProduct}
                    >
                      Search
                    </ButtonCompo>
                  </InputAdornment>
                ),
              },
            }}
          />
          <div className="w-full mt-5">
            {productData?.length === 0 ? (
              <p className="text-slate-400 font-semibold mt-[3rem] text-center">
                {message}
              </p>
            ) : (
              <div className="w-full">
                {productData?.map((product, i) => (
                  <Link to={`/product/${product?._id}`}>
                    <section
                      className="flex items-start gap-5 hover:bg-slate-100 px-3 py-2 rounded-[10px]"
                      onClick={handleClose}
                    >
                      <section>
                        <div className="w-[3rem] h-[3rem] bg-orange-200/70 rounded-[7px]">
                          <img
                            src={`${SERVER_URL}/api/v1/file/get/${product?.images[0]}`}
                            className="w-full h-full object-contain"
                            alt=""
                          />
                        </div>
                      </section>
                      <section>
                        <div className="flex items-center gap-1 flex-wrap">
                          <p className="font-semibold">{product?.title}</p>
                          <p className="text-[13px] text-slate-500">
                            {product?.catagory}
                          </p>
                        </div>
                      </section>
                    </section>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </DialogBox>
    </div>
  );
}

export default SearchDialogCompo;
