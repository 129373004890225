import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaMoneyBill1 } from "react-icons/fa6";
import { MdContactMail, MdDashboard } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { BsFillInboxesFill } from "react-icons/bs";
import { RiCoupon3Fill } from "react-icons/ri";
import { FaGift } from "react-icons/fa6";
import { FaImage } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";

function STDashboard() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  console.log(staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  const dataArray = [
    {
      title: "Products",
      icon: <AiFillProduct size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "/staff/products",
    },
    {
      title: "Add Product",
      icon: <AiFillProduct size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "/staff/product/add",
    },
    {
      title: "Catagories",
      icon: <BsFillInboxesFill size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "/staff/catagories",
    },
    {
      title: "New Orders",
      icon: <FaGift size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "/staff/orders/new",
    },
    {
      title: "Order History",
      icon: <FaGift size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "/staff/orders",
    },
    {
      title: "Coupons",
      icon: <RiCoupon3Fill size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "/staff/coupons",
    },
    {
      title: "Charges",
      icon: <FaMoneyBill1 size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "/staff/charges",
    },
    {
      title: "Users",
      icon: <FaUsers size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "/staff/users",
    },
    {
      title: "Inquiry",
      icon: <MdContactMail size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "/staff/contact",
    },
    {
      title: "IT Support",
      icon: <RiCustomerService2Fill size={"70px"} className="text-orange-400 hover:text-orange-600" />,
      link: "https://wa.me/9148054805/?text=Hi%20AppNest%0AWe%20are%20from%20NBS%0AWe%20are%20facing%20issue%20on%20website",
    }
  ];

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <h1 className="mt-[5rem] heading_text text-[35px] font-bold">
        Staff Dashboard
      </h1>
      <div className="w-[90vw] xl:w-[70vw] mt-[3rem]">
        <div className="grid grid-cols-5 gap-[7rem] place-items-center text-center">
          {dataArray?.map((item, i) => (
            <Link to={item?.link}>
              <section className="flex justify-center items-center flex-col gap-3">
                <span>{item?.icon}</span>
                <p className="font-semibold text-slate-700">{item?.title}</p>
              </section>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default STDashboard;
