import React, { useState } from "react";
import ButtonCompo from "./ButtonCompo";
import AlertBox from "./AlertBox";
import Loading from "./Loading";
import { axiosInstance, SERVER_URL } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { resetProductNumber } from "../store/cartNumber";
import logo from "../assets/companyLogo.png"

function PaymentOnline({
  products,
  totalPrice,
  coupon,
  discount,
  address,
  charges,
  chargeAmount,
  discountType,
}) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.auth?.userData);

  async function makePayment() {
    if (
      products?.length === 0 ||
      totalPrice === 0 ||
      Object.keys(address)?.length === 0
    ) {
      setOpen(true);
      setSuccess(false);
      setMessage("At least 1 product and address is required");
      return null;
    }
    const data = {
      products: products,
      totalPrice: totalPrice,
      coupon: coupon,
      discount: discount,
      address: address,
      charges: charges,
      chargeAmount: chargeAmount,
      discountType: discountType,
    };
    try {
      setLoading(true);
      const res = await axiosInstance.post("/api/v1/payment/checkout", data);
      const order = res.data?.data;
      // reset cart badge number
      dispatch(resetProductNumber());

      var options = {
        key: import.meta.env.VITE_RAZORPAY_TESTING_KEY_ID,
        amount: order?.amount_due,
        currency: "INR",
        name: "Nutribiosciences",
        description: "Buying health products",
        image: logo,
        order_id: order?.id,
        callback_url: `${SERVER_URL}/api/v1/payment/verification`,
        prefill: {
          name: `${userData?.fullName}`,
          email: userData?.email,
          contact: userData?.phone,
        },
        notes: {
          address: "Nutribiosciences",
        },
        theme: {
          color: "#FF8000",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
    }
  }

  // console.log(window)

  return (
    <>
      {loading && <Loading />}
      {success !== undefined && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <ButtonCompo className="!w-full !text-white !bg-[#FF8000] !font-semibold !h-[3rem] hover:!bg-orange-600" onClick={makePayment}>
        Proceed To pay
      </ButtonCompo>
    </>
  );
}

export default PaymentOnline;
