import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance, SERVER_URL } from "../constant";
import { IoLogOutSharp } from "react-icons/io5";
import AlertBox from "../component/AlertBox";
import ProductCard from "../component/ProductCard";
import ButtonCompo from "../component/ButtonCompo";
import AddToCart from "../component/AddToCart";
import videoImg from "../assets/videoreview.svg";
import CatagoryCard from "../component/CatagoryCard";
import logo from "../assets/companyLogo.png";
import { FaArrowRightLong } from "react-icons/fa6";

function Landing() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth?.userData);
  const [catagoryData, setCatagoryData] = useState([]);
  const [banners, setBanners] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [valuableProducts, setValuableProducts] = useState([]);
  const [videoData, setVideoData] = useState([]);

  // get catagory
  async function getCatagories() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/catagory/get/all/1/5`
      );
      // console.log(res.data?.data?.data);
      setCatagoryData(res.data?.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(true);
      setMessage(error?.response?.data?.message || "Please refresh the page.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get banners
  async function getBanners() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/banners/all`);
      // console.log(res.data?.data);
      setBanners(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(true);
      setMessage(error?.response?.data?.message || "Please refresh the page.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get top products
  async function getTopProducts() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/get/all/1/10?keyward=Top+Product`
      );
      // console.log(res.data?.data?.data);
      setTopProducts(res.data?.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(true);
      setMessage(error?.response?.data?.message || "Please refresh the page.");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get videos
  async function getVideos() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/review/video/get/all/3`
      );
      console.log(res.data?.data);
      setVideoData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(true);
      setMessage(error?.response?.data?.message || "Please refresh the page.");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get valuable products
  async function getValuableProducts() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/get/all/1/10?keyward=Valuable+Product`
      );
      // console.log(res.data?.data?.data);
      setValuableProducts(res.data?.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(true);
      setMessage(error?.response?.data?.message || "Please refresh the page.");
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // carousel logic
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const carouselInterval = setInterval(() => {
      setCurrentIndex((prev) => (Number(prev) + 1) % Number(banners.length));
    }, 3000);

    return () => clearInterval(carouselInterval);
  }, [banners.length]);

  useEffect(() => {
    getCatagories();
    getBanners();
    getTopProducts();
    getValuableProducts();
    getVideos();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <img src={logo} className="w-[10rem]" alt="" />
      </div>
    );
  }

  return (
    <div className="w-full flex justify-center items-center">
      {success !== undefined && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-full">
        <div className="w-full flex justify-center items-center mt-[3rem]">
          <div className="w-[100vw] flex justify-center items-center flex-col mt-[3rem]">
            {banners?.length > 0 ? (
              <div className="w-[100vw] mx-auto overflow-hidden relative">
                <div
                  className="w-full flex transition-transform duration-700"
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                  {banners?.map((banner, i) => (
                    <section className="w-[100vw] flex-shrink-0">
                      <img
                        src={`${SERVER_URL}/api/v1/file/get/${banner?.image}`}
                        loading="lazy"
                        alt={`Banner-${i + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </section>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="w-[90vw]">
              <div className="w-full mt-[3rem]">
                <h1 className="text-center heading_text mt-[5rem] uppercase text-[40px] font-extrabold text-orange-700 tracking-wide">
                  Our Top products
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full mt-[3rem] place-items-center">
                  {topProducts?.map((product) => (
                    <ProductCard productData={product} />
                  ))}
                </div>
                <div className="w-full flex justify-center">
                  <ButtonCompo variant={"outline"} href="/shop">
                    View All
                  </ButtonCompo>
                </div>
              </div>
              <div className="w-full mt-[3rem]">
                <h1 className="text-center heading_text mt-[5rem] uppercase text-[40px] font-extrabold text-orange-700 tracking-wide">
                  Our Valuable products
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full mt-[3rem] place-items-center">
                  {valuableProducts?.map((product) => (
                    <ProductCard productData={product} />
                  ))}
                </div>
                <div className="w-full flex justify-center">
                  <ButtonCompo variant={"outline"} href="/shop">
                    View All
                  </ButtonCompo>
                </div>
              </div>
            </div>
            <div className="w-full mt-[5rem] bg-[#FF8000]/10">
              <h1 className="text-center heading_text mt-[5rem] uppercase text-[40px] font-extrabold text-orange-700 tracking-wide">
                Our Valuable Customer
              </h1>
              <div className="flex w-full  justify-between items-center mt-[3rem] py-5">
                <section className="w-[30%] hidden xl:flex justify-center items-center">
                  <img src={videoImg} alt="" />
                </section>
                <div className="w-[100%] xl:w-[70%] grid grid-cols-1 gap-3 lg:grid-cols-3 place-items-center items-start py-[5rem]">
                  {videoData?.map((video, i) => (
                    <section className="w-[19rem] border rounded-[10px] border-[#ff80007a] bg-[#FFDDBB]">
                      <section className="w-full h-[10rem]">
                        <iframe
                          className="w-full h-full rounded-t-[10px]"
                          src={`https://www.youtube.com/embed/${video?.url}?autoplay=1&loop=1&playlist=${video?.url}&mute=1`}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                      </section>
                      <section className="px-3 mt-2">
                        <p className="font-semibold">{video?.fullName}</p>
                        <p className="text-slate-500 font-medium">
                          {video?.about}
                        </p>
                      </section>
                      <Link to={`/product/${video?.productId}`}>
                        <section className="w-full flex mt-2 gap-5 p-3 pb-0">
                          <section
                            className={`bg-orange-300 max-w-[5rem] flex-col h-[5rem] rounded-[10px] overflow-visible flex justify-center items-center relative`}
                          >
                            <img
                              src={`${SERVER_URL}/api/v1/file/get/${video?.product?.images[0]}`}
                              className="w-full h-auto object-cover"
                              alt=""
                              loading="lazy"
                            />
                          </section>
                          <section>
                            <p className="text-[15px] mb-1 text-slate-500">
                              {video?.product?.catagory}
                            </p>
                            <p className="font-semibold">
                              {video?.product?.title}
                            </p>
                            <p className="mt-1">
                              ₹
                              {video?.product?.variant[0]?.specialPrice === 0
                                ? video?.product?.variant[0]?.sellingPrice
                                : video?.product?.variant[0]?.specialPrice}
                              {video?.product?.variant[0]?.sellingPrice !==
                              video?.product?.variant[0]?.mrp ? (
                                <span className="ml-3 line-through text-slate-500 text-[16px]">
                                  ₹{video?.product?.variant[0]?.mrp}
                                </span>
                              ) : (
                                ""
                              )}
                              {video?.product?.variant[0]?.discount > 0 ? (
                                <span className="text-green-600 font-semibold">
                                  ({video?.product?.variant[0]?.discount}% off)
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                          </section>
                        </section>
                      </Link>
                      <section className="flex flex-col gap-3 mt-5 p-3 pt-1">
                        <AddToCart
                          productId={video?.product?._id}
                          productQuantity={`${video?.product?.variant[0]?.value} ${video?.product?.variant[0]?.unit}`}
                          discount={video?.product?.variant[0]?.discount}
                          productImg={video?.product?.images[0]}
                          productTitle={video?.product?.title}
                          productVariant={video?.product?.variant[0]}
                          totalPrice={
                            video?.product?.variant[0]?.specialPrice === 0
                              ? video?.product?.variant[0]?.sellingPrice
                              : video?.product?.variant[0]?.specialPrice
                          }
                          productCatagory={video?.product?.catagory}
                        />
                      </section>
                    </section>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-[90vw] flex justify-center flex-col items-center gap-[1rem] mt-[1rem]">
              <h1 className="text-center heading_text mt-[5rem] uppercase text-[40px] font-extrabold text-orange-700 tracking-wide">
                Catagories
              </h1>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 place-items-center gap-[1rem] mb-[1rem] mt-[3rem]">
                {catagoryData?.map((c, i) => (
                  <Link
                    to={`/shop?catagory=${c?.name}`}
                    className="text-center"
                  >
                    <section
                      className={`${
                        i % 2 === 0
                          ? "transform translate-y-[0%] xl:transform xl:translate-y-[-15%] flex justify-center items-center flex-col"
                          : "transform translate-y-[0%] xl:transform xl:translate-y-[15%] flex justify-center items-center flex-col"
                      }`}
                    >
                      <section
                        className={`${
                          i % 2 === 0 ? "bg-yellow-700" : "bg-yellow-400"
                        } w-[12rem] h-[12rem] rounded-[10px] overflow-visible flex justify-center items-center catagory-card-shadow-hover`}
                      >
                        <img
                          src={`${SERVER_URL}/api/v1/file/get/${c?.image}`}
                          className="w-[10rem] h-auto object-cover"
                          alt=""
                          loading="lazy"
                        />
                      </section>
                      <p className="mt-2 text-center text-[18px] text-orange-800 font-semibold">
                        {c?.name}
                      </p>
                    </section>
                  </Link>
                ))}
              </div>
              <div className="flex justify-center py-1">
                <Link
                  className="flex justify-end items-center gap-2 px-5 py-1 font-semibold text-[#FF8000]"
                  to={"/catagories"}
                >
                  See More{" "}
                  <span>
                    <FaArrowRightLong />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
