import React, { useEffect, useState } from "react";
import logo from "../assets/companyLogo.png";
import InputFieldsWithIcon from "./InputFieldsWithIcon";
import { IoSearch } from "react-icons/io5";
import profileIcon from "../assets/profileIcon.svg";
import cartIcon from "../assets/cartIcon.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import PopOverBox from "./PopoverBox";
import { Badge, InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FaUser } from "react-icons/fa6";
import { IoLogOutSharp } from "react-icons/io5";
import { axiosInstance, SERVER_URL } from "../constant";
import { logout } from "../store/auth.slice";
import AlertBox from "./AlertBox";
import Loading from "./Loading";
import { FaUserCircle } from "react-icons/fa";
import SearchCompo from "./SearchCompo";
import SearchDialogCompo from "./SearchDialogCompo";
import { IoHomeSharp } from "react-icons/io5";
import { FaShoppingBag } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { IoCall } from "react-icons/io5";

function Header() {
  const isAuthenticatedUser = useSelector(
    (state) => state.auth?.isAuthenticated
  );
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth?.userData);
  const cartNumber = useSelector((state) => state?.cartNumber?.productCart);
  const [bgColor, setBgColor] = useState(false);

  // logout
  async function logout() {
    try {
      setLoading(true);
      const res = await axiosInstance.get("/api/v1/user/logout");
      dispatch(logout());
      navigate("/");
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  // user popover
  const unknownUserPopover = [
    <Link className="text-[15px]" to={"/signup"}>
      <section className="w-[8rem] font-medium py-2 px-3 hover:bg-slate-100">
        Sign Up
      </section>
    </Link>,
    <Link className="text-[15px]" to={"/signin"}>
      <section className="w-[8rem] font-medium py-2 px-3 hover:bg-slate-100">
        Sign In
      </section>
    </Link>,
  ];

  // scroll
  function handleScroll() {
    if (window.scrollY > 0) {
      setBgColor(true);
    } else {
      setBgColor(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const signedInUserPopover = [
    <Link className="text-[15px]" to={"/profile"}>
      <section className="w-[10rem] font-medium py-2 px-3 hover:bg-slate-100 flex justify-start gap-2 items-center">
        <FaUser size={"15px"} className="text-[#FF8000]" /> My Profile
      </section>
    </Link>,
  ];

  return (
    <>
      {loading && <Loading />}
      <header
        className={`flex w-full justify-center items-center py-5 fixed top-0 z-[10000] ${
          bgColor === true ? "bg-[#ffedd0]" : ""
        }`}
      >
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw] flex justify-around items-center">
          <Link to={"/"}>
            <img src={logo} className="w-[7rem] md:w-[9rem]" alt="" />
          </Link>
          <section className="hidden lg:flex gap-[24px]">
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive
                  ? "font-bold border-b-[3px] border-orange-700 text-[15.5px] uppercase text-orange-700"
                  : "font-medium text-[15.5px] uppercase text-orange-700"
              }
            >
              home
            </NavLink>
            <NavLink
              to={"/shop"}
              className={({ isActive }) =>
                isActive
                  ? "font-bold border-b-[3px] border-orange-700 text-[15.5px] uppercase text-orange-700"
                  : "font-medium text-[15.5px] uppercase text-orange-700"
              }
            >
              shop
            </NavLink>
            <NavLink
              to={"/about-us"}
              className={({ isActive }) =>
                isActive
                  ? "font-bold border-b-[3px] border-orange-700 text-[15.5px] uppercase text-orange-700"
                  : "font-medium text-[15.5px] uppercase text-orange-700"
              }
            >
              ABOUT US
            </NavLink>
            <NavLink
              to={"/contact-us"}
              className={({ isActive }) =>
                isActive
                  ? "font-bold border-b-[3px] border-orange-700 text-[15.5px] uppercase text-orange-700"
                  : "font-medium text-[15.5px] uppercase text-orange-700"
              }
            >
              contact us
            </NavLink>
          </section>
          <section className="flex lg:hidden">
            <section className="fixed bottom-0 left-0 w-full flex justify-between sm:justify-center md:gap-[3rem] items-center bg-white px-3 py-4 border">
              <NavLink
                to={"/"}
                className={({ isActive }) =>
                  isActive
                    ? "font-bold text-[12.5px] uppercase text-[#FF8000]"
                    : "font-medium text-[12.5px] uppercase text-slate-500/80"
                }
              >
                <section className="flex justify-center items-center flex-col gap-1">
                  <IoHomeSharp size={"21px"} />
                  <p className="font-semibold">home</p>
                </section>
              </NavLink>
              <NavLink
                to={"/shop"}
                className={({ isActive }) =>
                  isActive
                    ? "font-bold text-[12.5px] uppercase text-[#FF8000]"
                    : "font-medium text-[12.5px] uppercase text-slate-500/80"
                }
              >
                <section className="flex justify-center items-center flex-col gap-1">
                  <FaShoppingBag size={"21px"} />
                  <p className="font-semibold">shop</p>
                </section>
              </NavLink>
              {isAuthenticatedUser === true ? (
                <NavLink
                  to={"/profile"}
                  className={({ isActive }) =>
                    isActive
                      ? "font-bold text-[12.5px] uppercase text-[#FF8000]"
                      : "font-medium text-[12.5px] uppercase text-slate-500/80"
                  }
                >
                  <section className="flex justify-center items-center flex-col gap-1">
                    <FaUserAlt size={"21px"} />
                    <p className="font-semibold">profile</p>
                  </section>
                </NavLink>
              ) : (
                <PopOverBox
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  trigger={
                    <section className="flex justify-center items-center flex-col gap-1 font-medium text-[12.5px] uppercase text-slate-500/80">
                      <FaUserAlt size={"21px"} />
                      <p className="font-semibold">profile</p>
                    </section>
                  }
                  dataArray={unknownUserPopover}
                />
              )}
              <NavLink
                to={"/contact-us"}
                className={({ isActive }) =>
                  isActive
                    ? "font-bold text-[12.5px] uppercase text-[#FF8000]"
                    : "font-medium text-[12.5px] uppercase text-slate-500/80"
                }
              >
                <section className="flex justify-center items-center flex-col gap-1">
                  <IoCall size={"21px"} />
                  <p className="font-semibold">Contact</p>
                </section>
              </NavLink>
            </section>
          </section>
          <section>
            <section className="hidden md:flex">
              <SearchCompo />
            </section>
          </section>
          {isAuthenticatedUser === true ? (
            <div className="flex items-center gap-[13px] md:gap-[15px] justify-center">
              <Link to={"/profile"}>
                <section className="w-[2.6rem] h-[2.6rem] hidden lg:flex">
                  {userData?.image === undefined ? (
                    <button className="w-[2.7rem]">
                      <FaUserCircle className="text-[2.5rem] text-[#FF8000]" />
                    </button>
                  ) : (
                    <img
                      src={`${SERVER_URL}/api/v1/file/get/${userData?.image}`}
                      className="w-full h-full object-cover rounded-full"
                    />
                  )}
                </section>
              </Link>
              <section className="flex md:hidden">
                <SearchDialogCompo />
              </section>
              <Link className="w-[2.6rem]" to={"/cart"}>
                <Badge
                  badgeContent={cartNumber}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#FF8000", // Change badge background color
                      color: "white", // Change badge text color
                      fontWeight: "900",
                    },
                  }}
                >
                  <img src={cartIcon} className="" />
                </Badge>
              </Link>
            </div>
          ) : (
            <div className="flex items-center justify-center gap-[13px] md:gap-[2rem]">
              <PopOverBox
                trigger={
                  <button className="w-[2.7rem] hidden lg:flex ">
                    <FaUserCircle className="text-[2.5rem] text-[#FF8000]" />
                  </button>
                }
                dataArray={unknownUserPopover}
              />
              <section className="flex md:hidden">
                <SearchDialogCompo />
              </section>
              <Link className="w-[2.4rem] md:w-[2.6rem]" to={"/cart"}>
                <img src={cartIcon} className="" />
              </Link>
            </div>
          )}
        </div>
      </header>
    </>
  );
}

export default Header;

// Next release

//  <NavLink
//   to={"/health-tips"}
//   className={({ isActive }) =>
//     isActive
//       ? "font-bold border-b-[3px] border-orange-700 text-[15.5px] uppercase text-orange-700"
//       : "font-medium text-[15.5px] uppercase text-orange-700"
//   }
// >
//   HEALTH TIPS
// </NavLink>

// <NavLink
//   to={"/blog"}
//   className={({ isActive }) =>
//     isActive
//       ? "font-bold border-b-[3px] border-orange-700 text-[15.5px] uppercase text-orange-700"
//       : "font-medium text-[15.5px] uppercase text-orange-700"
//   }
//   >
//     BLOG
// </NavLink>
