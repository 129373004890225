import React, { useEffect, useState } from "react";
import { axiosInstance } from "../constant";
import CatagoryCard from "../component/CatagoryCard";
import ProductCard from "../component/ProductCard";
import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";
import { Link, useSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import { FaArrowRightLong } from "react-icons/fa6";

function ProductPage() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [catagoryData, setCatagoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [productData, setProductData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams] = useSearchParams();

  const catagory = searchParams.get("catagory");

  // get catagory
  async function getCatagories() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/catagory/get/all/1/5`
      );
      setCatagoryData(res.data?.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get products
  async function getProducts() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/get/all/${page}/20?catagory=${catagory || ""}`
      );
      window.scrollTo(0, 0);
      setProductData(res.data?.data?.data);
      setTotalPages(res.data?.data?.totalPages);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function handlePageChange(event, value) {
    setPage(value);
  }

  useEffect(() => {
    getCatagories();
  }, []);

  useEffect(() => {
    getProducts();
  }, [catagory, page]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex flex-col justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-full bg-[#FFE6BC]/40 backdrop-blur-[20px] flex justify-center items-center">
          <div className="w-fit overflow-auto relative py-2 pb-7">
            <div
              className="mt-[5rem] w-fit flex justify-start sm:justify-center px-5 items-start text-center gap-[1rem] sm:gap-[3rem] py-[3rem] pb-[1rem] overflow-auto"
              style={{ scrollbarWidth: "none" }}
            >
              {catagoryData?.map((e, i) => (
                <CatagoryCard title={e?.name} url={e?.image} index={i} />
              ))}
            </div>
            {catagoryData?.length > 4 ? (
              <Link className="flex justify-end items-center gap-2 px-5 py-2 font-semibold text-[#FF8000] fixed sm:absolute bottom-0 right-0" to={"/catagories"}>
                See More{" "}
                <span>
                  <FaArrowRightLong />
                </span>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <h1 className="text-center heading_text mt-[5rem] uppercase text-[40px] font-extrabold text-orange-700 tracking-wide">
          Our All products
        </h1>
        <div className="w-[90vw] mt-[3rem] py-5">
          <div className="w-full grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 place-items-center gap-[3rem]">
            {productData?.map((product, i) => (
              <ProductCard productData={product} />
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center items-center py-5">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            className="!font-semibold"
          />
        </div>
      </div>
    </>
  );
}

export default ProductPage;
