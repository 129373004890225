import React, { useState } from "react";
import ButtonCompo from "./ButtonCompo";
import InputFieldsWithIcon from "./InputFieldsWithIcon";
import Loading from "./Loading";
import { axiosInstance } from "../constant";
import DialogBox from "./DialogBox";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { reloadToggle } from "../store/reload.slice";
import AlertBox from "./AlertBox";

function AddAddress({setUserReload}) {
  const [streetA1, setStreetA1] = useState("");
  const [streetA2, setStreetA2] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.auth?.userData);
  const dispatch = useDispatch()

  // add address
  async function addAddress() {
    if (
      [streetA1, pincode, state, city, fullName, phone].some(
        (item) => item?.length === 0
      )
    ) {
      setOpen(true);
      setSuccess(false);
      setMessage("All fields are required");
      return null;
    }
    if (pincode?.length !== 6) {
      setOpen(true);
      setSuccess(false);
      setMessage("Pincode Invalid");
      return null;
    }
    const data = {
      streetAddress1: streetA1,
      streetAddress2: streetA2,
      city: city,
      pincode: pincode,
      state: state,
      phone: phone,
      fullName: fullName,
    };
    try {
      setLoading(true);
      const res = await axiosInstance.post("/api/v1/user/address/add", data);
      setUserReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setMessage("Address added successsfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
      setFullName("");
      setPhone("");
      setStreetA1("");
      setStreetA2("");
      setPincode("");
      setCity("");
      setState("");
    }
  }

  // it is my address
  async function myAddress(check) {
    if (check) {
      setFullName(userData?.fullName);
      setPhone(userData?.phone);
    } else {
      setFullName("");
      setPhone("");
    }
  }

  return (
    <>
      {loading && <Loading />}
      {success !== undefined && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <DialogBox
        trigger={
          <ButtonCompo className="flex gap-2 !bg-white !text-[#FF8000] !px-5 !py-2 !font-semibold !items-center shadow-md">
            Add Address
          </ButtonCompo>
        }
        actionButton={<ButtonCompo onClick={addAddress}>Save</ButtonCompo>}
        title="Add A New Address"
        maxWidth="lg"
        className="!z-[10000]"
      >
        <div className="w-full space-y-3">
          <InputFieldsWithIcon
            label="Name"
            isImp={true}
            value={fullName}
            onChange={(event) => setFullName(event?.target?.value)}
            size="small"
          />
          <InputFieldsWithIcon
            label="Phone Number"
            isImp={true}
            value={phone}
            onChange={(event) => setPhone(event?.target?.value)}
            size="small"
          />
          <section className="flex items-center gap-2">
            <Checkbox
              sx={{
                padding: 0, // Removes padding from the checkbox itself
                color: "#FF8000",
                "&.Mui-checked": {
                  color: "#FF8000",
                },
              }}
              onChange={(event) => myAddress(event?.target?.checked)}
            />
            <p className="text-slate-800 text-[16px]">It's my address</p>
          </section>
          <InputFieldsWithIcon
            label="Street Address 1"
            isImp={true}
            value={streetA1}
            onChange={(event) => setStreetA1(event?.target?.value)}
            size="small"
          />
          <InputFieldsWithIcon
            label="Street Address 2"
            isImp={true}
            value={streetA2}
            onChange={(event) => setStreetA2(event?.target?.value)}
            size="small"
          />
          <InputFieldsWithIcon
            label="Pincode"
            isImp={true}
            value={pincode}
            onChange={(event) => setPincode(event?.target?.value)}
            size="small"
          />
          <InputFieldsWithIcon
            label="City"
            isImp={true}
            value={city}
            onChange={(event) => setCity(event?.target?.value)}
            size="small"
          />
          <InputFieldsWithIcon
            label="State"
            value={state}
            isImp={true}
            onChange={(event) => setState(event?.target?.value)}
            size="small"
          />
        </div>
      </DialogBox>
    </>
  );
}

export default AddAddress;
