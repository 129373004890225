import React, { useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ButtonCompo from "./ButtonCompo";
import AlertBox from "./AlertBox";
import Loading from "./Loading";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { increaseProductNumber } from "../store/cartNumber";
import { axiosInstance } from "../constant";
import { useNavigate } from "react-router-dom";

function AddToCart({
  productId,
  productQuantity,
  discount,
  totalPrice,
  productTitle,
  productImg,
  productVariant,
  productCatagory,
  isBuyNow,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated)

  // get reviews
  async function addToCart() {
    if(!isAuthenticated) return navigate("/signin")
    if (
      !productId ||
      !productQuantity ||
      !totalPrice ||
      !productTitle ||
      !productImg ||
      !productVariant ||
      !productCatagory
    ) {
      setOpen(true);
      setSuccess(false);
      setMessage("Something went wrong");
      return null
    }
    const data = {
      productId: productId,
      productQuantity: productQuantity,
      discount: discount || 0,
      totalPrice: totalPrice,
      productTitle: productTitle,
      productImage: productImg,
      productVariant: productVariant,
      productCatagory: productCatagory,
      isBuyNow: isBuyNow
    };
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/api/v1/cart/add`, data);
      dispatch(increaseProductNumber());
      setIsAdded(true);
      if(isBuyNow === true) {
        window.location.href = "/cart"
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <Loading />}
      {success !== undefined && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {isAdded === true ? (
        <ButtonCompo
          variant={"primary"}
          className="!rounded-[10px] !w-full !bg-green-600 !text-white !font-semibold"
          startIcon={<FaCheckCircle />}
          {...props}
        >
          Added To Cart
        </ButtonCompo>
      ) : (
        <ButtonCompo
          variant={"primary"}
          className="!rounded-[10px] !w-full !bg-[#FF8000] hover:!bg-[#FF7000] !text-white !font-semibold !text-[12px] md:!text-[16px]"
          startIcon={<AddShoppingCartIcon />}
          onClick={addToCart}
          {...props}
        >
          {
            isBuyNow === true ? "Buy Now" : "Add To Cart"
          }
        </ButtonCompo>
      )}
    </>
  );
}

export default AddToCart;
