import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Heading from "../../component/Heading";
import AccrodianBox from "../../component/AccrodianBox";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import ButtonCompo from "../../component/ButtonCompo";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { axiosInstance, SERVER_URL } from "../../constant";
import AutocompleteCompo from "../../component/AutocompleteCompo";
import { Chip } from "@mui/material";
import { MdCloudUpload, MdDelete, MdEditSquare } from "react-icons/md";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import { RiAddBoxFill } from "react-icons/ri";
import { TbAlertCircle } from "react-icons/tb";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SelectField from "../../component/SelectField";

function STEditProduct() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const { productId } = useParams();
  const [catagoryData, setCatagoryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [variants, setVariants] = useState([]);

  // basic information form input hook
  const [selectedCatagory, setSelectedCatagory] = useState("");
  const [selectedKeyward, setSelectedKeyward] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState();
  const [mktDate, setMktDate] = useState();
  const [mktBy, setMktBy] = useState("");
  const [expDate, setExpDate] = useState();
  const [origin, setOrigin] = useState("");
  const [isActive, setIsActive] = useState(true);

  // variant fields hooks
  const [mrp, setMrp] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [specialPrice, setSpecialPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [unit, setUnit] = useState("");
  const [value, setValue] = useState(0);

  // unit array
  const unitArray = [
    "gm",
    "kg",
    "ml",
    "L",
    "pcs",
    "Box",
    "Dozen",
    "Carton",
    "Bottles",
  ];

  // get catagories list
  async function getCatagories() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/product/catagory/list`);
      const rawData = res.data?.data;
      let catagories = [];
      rawData?.map((item) => {
        catagories.push(item?.name);
      });
      setCatagoryData(catagories);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // some pre-defined keywards
  const keywardArray = [
    {
      label: "Top Product",
      id: "top_product",
    },
    {
      label: "Valuable Product",
      id: "top_product",
    },
  ];

  // keyward delete
  function handleKeywardDelete(index) {
    setSelectedKeyward((prev) => {
      let array = prev;
      let firstPart = array.slice(0, index);
      let secondPart = array.slice(index + 1, prev?.length);
      let finalArray = [...firstPart, ...secondPart];
      return finalArray;
    });
  }

  // edit product
  async function editProduct() {
    const data = {
      title: title,
      subTitle: subtitle,
      description: description,
      catagory: selectedCatagory,
      keyward: selectedKeyward,
      mktBy: mktBy,
      mktDate:
        new Date(mktDate?.$d).toLocaleDateString() === "Invalid Date"
          ? ""
          : new Date(mktDate?.$d).toLocaleDateString(),
      expDate: expDate,
      origin: origin,
      productId: productId,
      isActive: isActive,
    };
    try {
      setLoading(true);
      const res = await axiosInstance.put("/api/v1/product/edit", data);
      setOpen(true);
      setSuccess(true);
      setMessage("Product updated successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add image
  const fileRef = useRef(null);
  const [files, setFiles] = useState([]);
  async function uploadImage() {
    if (files?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Select at least 1 image");
      return null;
    }
    const formData = new FormData();
    Array.from(files).map((file) => {
      formData.append("productImg", file);
    });
    formData.append("productId", productId);
    try {
      setLoading(true);
      const res = await axiosInstance.post(
        "/api/v1/product/image/add",
        formData
      );
      setImages(res.data?.data?.images);
      setSelectedImages([]);
      setOpen(true);
      setSuccess(true);
      setMessage("Image added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // handle image
  const [selectedImages, setSelectedImages] = useState([]);
  function viewImage(event) {
    const allImages = event?.target?.files;
    let preSelectedImages = [];
    Array.from(allImages)?.map((img) => {
      const previewImage = URL.createObjectURL(img);
      preSelectedImages.push(previewImage);
    });
    setSelectedImages((prev) => {
      return [...prev, ...preSelectedImages];
    });
    setFiles((prev) => {
      return [...prev, ...allImages];
    });
  }

  // delete image
  async function deleteImage(url) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/product/image/delete/${productId}/${url}`
      );
      console.log(res);
      setImages(res.data?.data?.images);
      setOpen(true);
      setSuccess(true);
      setMessage("Image deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add variant
  async function addVariant() {
    if (value === 0 || unit === "" || mrp === 0 || sellingPrice === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("All fields is required");
      return null;
    }
    const data = {
      value: value,
      unit: unit,
      mrp: mrp,
      sellingPrice: sellingPrice,
      specialPrice: specialPrice,
      discount: discount,
      productId: productId,
    };
    try {
      setLoading(true);
      const res = await axiosInstance.post("/api/v1/product/variant/add", data);
      setVariants(res.data?.data?.variant);
      setOpen(true);
      setSuccess(true);
      setMessage("Variant added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // edit variant
  async function editVariant(variantId) {
    const data = {
      value: value,
      unit: unit,
      mrp: mrp,
      sellingPrice: sellingPrice,
      specialPrice: specialPrice,
      discount: discount,
      productId: productId,
      variantId: variantId,
    };
    try {
      setLoading(true);
      const res = await axiosInstance.put(
        "/api/v1/product/variant/update",
        data
      );
      setVariants(res.data?.data?.variant);
      setOpen(true);
      setSuccess(true);
      setMessage("Variant edited successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteVariant(variantId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/product/variant/delete/${productId}/${variantId}`
      );
      setVariants(res.data?.data?.variant);
      setOpen(true);
      setSuccess(true);
      setMessage("Variant deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // discount calculation
  useEffect(() => {
    const discountValue = Math.ceil(Number(mrp * (discount / 100)));
    const special = mrp - discountValue;
    if (discount > 0) {
      setSpecialPrice(special);
    }
  }, [mrp, discount]);

  // number validation
  const numberValidate = (input) => {
    if (isNaN(input)) {
      return 0;
    } else {
      return input;
    }
  };

  // set value for edit
  function setEditValue(data) {
    setValue(data?.value);
    setUnit(data?.unit);
    setMrp(data?.mrp);
    setSellingPrice(data?.sellingPrice);
    setDiscount(data?.discount);
    setSpecialPrice(data?.specialPrice);
  }

  // get product
  async function getProduct() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/get/single/${productId}`
      );
      const rawData = res.data?.data;
      console.log(rawData);
      setTitle(rawData?.title);
      setSubtitle(rawData?.subTitle);
      setDescription(rawData?.description);
      setSelectedCatagory(rawData?.catagory);
      setSelectedKeyward(rawData?.keyward);
      setImages(rawData?.images);
      setVariants(rawData?.variant);
      setMktBy(rawData?.mktBy);
      setMktDate(rawData?.mktDate);
      setExpDate(rawData?.expDate);
      setOrigin(rawData?.origin);
      setIsActive(rawData?.isActive);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // active array
  const isActiveArray = [
    {
      title: "Enabled",
      value: true,
    },
    {
      title: "Disabled",
      value: false,
    },
  ];

  useEffect(() => {
    getProduct();
    getCatagories();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Edit Product</Heading>
          <div className="mt-5">
            <AccrodianBox
              title={
                <p className="font-bold">
                  <span className="mr-2 bg-slate-200 text-slate-700 rounded-full px-3 text-[17px] font-medium">
                    Step 1
                  </span>
                  Product Basic Details
                </p>
              }
              titleClass={"!text-[18px] !font-semibold"}
              id={1}
              defaultExpanded
            >
              <div className="w-full space-y-3">
                <div className="grid grid-cols-4 gap-3">
                  <InputFieldsWithIcon
                    label="Title"
                    placeholder="Add a attractive title"
                    onChange={(event) => setTitle(event?.target?.value)}
                    isImp={true}
                    value={title}
                  />
                  <InputFieldsWithIcon
                    label="Subtitle"
                    placeholder="Add a little descriptive short subtitle"
                    onChange={(event) => setSubtitle(event?.target?.value)}
                    isImp={true}
                    value={subtitle}
                  />
                  <AutocompleteCompo
                    label="Catagory"
                    dataArray={catagoryData}
                    placeholder={"Select a catagory"}
                    onChange={(event) =>
                      setSelectedCatagory(event?.target?.textContent)
                    }
                    isImp={true}
                    value={selectedCatagory}
                  />
                  <AutocompleteCompo
                    label="Keywards"
                    dataArray={keywardArray}
                    placeholder={"Select keywards"}
                    onChange={(event) =>
                      setSelectedKeyward((prev) => {
                        if (event?.target?.textContent !== "") {
                          return [...prev, event.target.textContent];
                        } else {
                          return prev;
                        }
                      })
                    }
                    isImp={true}
                    getOptionLabel={(option) => option.label}
                    multiple
                  />
                  <InputFieldsWithIcon
                    label="Manufacturer By"
                    placeholder="Name of manufacture company"
                    onChange={(event) => setMktBy(event?.target?.value)}
                    isImp={true}
                    value={mktBy}
                  />
                  <InputFieldsWithIcon
                    label="Origin Country"
                    placeholder="Origin country of the product"
                    onChange={(event) => setOrigin(event?.target?.value)}
                    isImp={true}
                    value={origin}
                  />
                  <section className="space-y-3 flex flex-col">
                    <label>Manufacture Date</label>
                    <DatePicker
                      value={dayjs(mktDate, "MM/DD/YYYY")}
                      onChange={(event) => setMktDate(event)}
                    />
                  </section>
                  <InputFieldsWithIcon
                    label="Expiry"
                    placeholder="Best before or Date (e.g, 11/12/2024)"
                    onChange={(event) => setExpDate(event?.target?.value)}
                    isImp={true}
                    value={expDate}
                  />
                  <SelectField
                    label={"Enable/Disable"}
                    value={isActive}
                    valueArray={isActiveArray}
                    setValue={(event) => setIsActive(event?.target?.value)}
                  />
                </div>
                {selectedKeyward?.length > 0 && (
                  <div>
                    <p className="mb-3">Keywards</p>
                    <div className="space-x-3">
                      {selectedKeyward.map((e, i) => (
                        <Chip
                          label={e}
                          onDelete={() => handleKeywardDelete(i)}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <InputFieldsWithIcon
                  label="Description"
                  placeholder="Add descripton of the product"
                  multiline
                  onChange={(event) => setDescription(event?.target?.value)}
                  isImp={true}
                  value={description}
                />
              </div>
              <div className="flex justify-end w-full mt-5">
                <ButtonCompo variant={"primary"} onClick={editProduct}>
                  Save & Continue
                </ButtonCompo>
              </div>
            </AccrodianBox>
            <AccrodianBox
              title={
                <p className="font-bold">
                  <span className="mr-2 bg-slate-200 text-slate-700 rounded-full px-3 text-[17px] font-medium">
                    Step 2
                  </span>
                  Product Images
                </p>
              }
              titleClass={"!text-[18px] !font-semibold"}
              id={2}
            >
              <div className="w-full">
                <input
                  type="file"
                  className="hidden"
                  ref={fileRef}
                  multiple
                  onChange={(event) => viewImage(event)}
                  accept=".png"
                />
                {images?.length < 6 &&
                selectedImages < 6 &&
                productId !== "" ? (
                  <div>
                    <ButtonCompo
                      startIcon={<MdCloudUpload />}
                      variant={"primary"}
                      onClick={() => fileRef?.current?.click()}
                    >
                      Add Images
                    </ButtonCompo>
                  </div>
                ) : (
                  ""
                )}
                {images?.length === 0 && selectedImages?.length === 0 ? (
                  <div>
                    <NotFound width="15rem" />
                  </div>
                ) : (
                  <div className="w-full flex flex-wrap gap-3 mt-5">
                    {selectedImages?.map((image) => (
                      <section className="border rounded-[10px] p-3">
                        <section className="w-[10rem] h-[10rem]">
                          <img
                            src={image}
                            className="w-full h-full object-cover"
                            alt=""
                          />
                        </section>
                      </section>
                    ))}
                    {images?.map((image) => (
                      <section className="border rounded-[10px] p-3">
                        <section className="w-[10rem] h-[10rem]">
                          <img
                            src={`${SERVER_URL}/api/v1/file/get/${image}`}
                            className="w-full h-full object-cover"
                            alt=""
                          />
                        </section>
                        <ButtonCompo
                          className="!w-full !bg-red-500 !mt-3 !text-white !font-semibold"
                          onClick={() => deleteImage(image)}
                        >
                          Delete
                        </ButtonCompo>
                      </section>
                    ))}
                  </div>
                )}
                {selectedImages?.length > 0 && (
                  <div className="w-full flex justify-end mt-5">
                    <ButtonCompo variant={"primary"} onClick={uploadImage}>
                      Save & Continue
                    </ButtonCompo>
                  </div>
                )}
              </div>
            </AccrodianBox>
            <AccrodianBox
              title={
                <p className="font-bold">
                  <span className="mr-2 bg-slate-200 text-slate-700 rounded-full px-3 text-[17px] font-medium">
                    Step 3
                  </span>
                  Configure Prices and Variants
                </p>
              }
              titleClass={"!text-[18px] !font-semibold"}
              id={3}
            >
              <div className="w-full">
                <div className="w-full flex justify-start">
                  <DialogBox
                    trigger={
                      productId?.length > 0 ? (
                        <ButtonCompo
                          startIcon={<RiAddBoxFill />}
                          variant={"primary"}
                        >
                          Add Variant
                        </ButtonCompo>
                      ) : (
                        ""
                      )
                    }
                    maxWidth="sm"
                    title="Add A Variant"
                    actionButton={
                      <ButtonCompo onClick={addVariant}>Save</ButtonCompo>
                    }
                  >
                    <div className="w-full space-y-3">
                      <InputFieldsWithIcon
                        label="Quantity"
                        isImp={true}
                        onChange={(event) => setValue(event?.target?.value)}
                      />
                      <AutocompleteCompo
                        label="Unit"
                        dataArray={unitArray}
                        isImp={true}
                        onChange={(event) =>
                          setUnit(event?.target?.textContent)
                        }
                      />
                      <InputFieldsWithIcon
                        label="MRP"
                        value={mrp}
                        onChange={(event) => {
                          const validatedValue = numberValidate(
                            event?.target?.value
                          );
                          setMrp(validatedValue);
                        }}
                        isImp={true}
                      />
                      <InputFieldsWithIcon
                        label="Selling Price"
                        onChange={(event) =>
                          setSellingPrice(Number(event?.target?.value))
                        }
                        isImp={true}
                      />
                      <InputFieldsWithIcon
                        label="Discount (in %)"
                        value={discount}
                        onChange={(event) =>
                          setDiscount(Number(event?.target?.value))
                        }
                      />
                      <InputFieldsWithIcon
                        label="Special Price"
                        value={specialPrice}
                        onChange={(event) =>
                          setSpecialPrice(Number(event?.target?.value))
                        }
                      />
                    </div>
                  </DialogBox>
                </div>
                <div className="w-full mt-5">
                  {variants?.length === 0 ? (
                    <div>
                      <NotFound width="15rem" />
                    </div>
                  ) : (
                    <div className="w-full">
                      <div className="w-full grid grid-cols-3 gap-3">
                        {variants?.map((e, i) => (
                          <section className="border p-3 rounded-[5px] shadow-md">
                            <div className="grid grid-cols-3 w-full gap-3">
                              <section>
                                <p className="text-slate-500 text-[15px]">
                                  Quantity
                                </p>
                                <p className="font-medium">
                                  {e?.value} {e?.unit}
                                </p>
                              </section>
                              <section>
                                <p className="text-slate-500 text-[15px]">
                                  MRP
                                </p>
                                <p className="font-medium">₹{e?.mrp}</p>
                              </section>
                              <section>
                                <p className="text-slate-500 text-[15px]">
                                  Selling Price
                                </p>
                                <p className="font-medium">
                                  ₹{e?.sellingPrice}
                                </p>
                              </section>
                              <section>
                                <p className="text-slate-500 text-[15px]">
                                  Discount
                                </p>
                                <p className="font-medium">{e?.discount}%</p>
                              </section>
                              <section>
                                <p className="text-slate-500 text-[15px]">
                                  Special Price
                                </p>
                                <p className="font-medium">
                                  ₹{e?.specialPrice}
                                </p>
                              </section>
                            </div>
                            <div className="w-full justify-end flex gap-3">
                              <DialogBox
                                trigger={
                                  <ButtonCompo
                                    className="flex gap-[4px] !text-slate-400/90 !font-semibold !text-[13px]"
                                    onClick={() => setEditValue(e)}
                                  >
                                    <MdEditSquare size={"18px"} /> Edit
                                  </ButtonCompo>
                                }
                                maxWidth="sm"
                                title="Edit Variant"
                                actionButton={
                                  <ButtonCompo
                                    onClick={() => editVariant(e?.variantId)}
                                  >
                                    Edit
                                  </ButtonCompo>
                                }
                              >
                                <div className="w-full space-y-3">
                                  <InputFieldsWithIcon
                                    label="Quantity"
                                    value={value}
                                    isImp={true}
                                    onChange={(event) =>
                                      setValue(event?.target?.value)
                                    }
                                  />
                                  <AutocompleteCompo
                                    label="Unit"
                                    dataArray={unitArray}
                                    value={unit}
                                    isImp={true}
                                    onChange={(event) =>
                                      setUnit(event?.target?.textContent)
                                    }
                                  />
                                  <InputFieldsWithIcon
                                    label="MRP"
                                    value={mrp}
                                    onChange={(event) => {
                                      const validatedValue = numberValidate(
                                        event?.target?.value
                                      );
                                      setMrp(validatedValue);
                                    }}
                                    isImp={true}
                                  />
                                  <InputFieldsWithIcon
                                    label="Selling Price"
                                    onChange={(event) =>
                                      setSellingPrice(
                                        Number(event?.target?.value)
                                      )
                                    }
                                    value={sellingPrice}
                                    isImp={true}
                                  />
                                  <InputFieldsWithIcon
                                    label="Discount (in %)"
                                    value={discount}
                                    onChange={(event) =>
                                      setDiscount(Number(event?.target?.value))
                                    }
                                  />
                                  <InputFieldsWithIcon
                                    label="Special Price"
                                    value={specialPrice}
                                    onChange={(event) =>
                                      setSpecialPrice(
                                        Number(event?.target?.value)
                                      )
                                    }
                                  />
                                </div>
                              </DialogBox>
                              <ButtonCompo
                                className="flex gap-[4px] !text-slate-400/90 !font-semibold !text-[13px]"
                                onClick={() => deleteVariant(e?.variantId)}
                              >
                                <MdDelete size={"18px"} /> Remove
                              </ButtonCompo>
                            </div>
                          </section>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </AccrodianBox>
          </div>
        </div>
      </div>
    </>
  );
}

export default STEditProduct;
