import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import PopOverBox from "../../component/PopoverBox";
import PaginationBar from "../../component/PaginationBar";

function STAllProducts() {
  // navigate to home if staff is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [productData, setProductData] = useState([]);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const alertRef = useRef(null);
  const [deleteId, setDeleteId] = useState("");
  const [deleteTitle, setDeleteTitle] = useState("");

  // get products
  async function getProducts() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/get/all/${page}/20?search=${search}`
      );
      setProductData(res.data?.data?.data);
      setTotalPages(res.data?.data?.totalPages);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // delete product
  async function deleteProduct() {
    if (deleteConfirmation === false) {
      return null;
    }
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/product/delete/${deleteId}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Product deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setFile(null);
      setDeleteConfirmation(false);
      setDeleteId("");
      setDeleteTitle("");
      setLoading(false);
    }
  }

  const handleAlertOpen = (productId, productTitle) => {
    if (alertRef.current) {
      setDeleteId(productId);
      setDeleteTitle(productTitle);
      alertRef.current.handleOpen();
    }
  };

  function onSearch() {
    setReload((prev) => !prev);
  }

  function onReload() {
    setSearch("");
    setReload((prev) => !prev);
  }

  useEffect(() => {
    getProducts();
  }, [reload, page]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <DialogBox
          ref={alertRef}
          actionButton={
            <ButtonCompo
              onClick={() => {
                setDeleteConfirmation(true);
                deleteProduct();
              }}
            >
              <span className="text-red-600 font-semibold text-[16px]">
                Delete
              </span>
            </ButtonCompo>
          }
          closeActionButton={"cancel"}
        >
          <div>
            <p>
              You're going to delete{" "}
              <span className="font-semibold">{deleteTitle}</span>
            </p>
            <p className="mt-3">
              Do want to{" "}
              <span className="text-red-600 font-semibold">delete</span> it?
            </p>
          </div>
        </DialogBox>
        <div className="w-[90vw]">
          <Heading>Products</Heading>
          <div className="w-full mt-5">
            <div className="w-full flex justify-between gap-3">
              <section className="flex gap-3 items-center justify-center">
                <TextField
                  size="small"
                  placeholder="Search"
                  onChange={(event) => setSearch(event?.target?.value)}
                />
                <ButtonCompo variant={"primary"} onClick={onSearch}>
                  Search
                </ButtonCompo>
              </section>
              <section className="flex items-center gap-3">
                <ButtonCompo
                  variant={"outline"}
                  startIcon={<IoMdRefresh />}
                  onClick={onReload}
                >
                  Refresh
                </ButtonCompo>
                <ButtonCompo
                  variant={"primary"}
                  startIcon={<MdAddBox />}
                  href="/staff/product/add"
                >
                  New Product
                </ButtonCompo>
              </section>
            </div>
            {productData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Title
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Catagory
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Keywards
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Total Variants
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Avarage Rating
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Total Images
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Options
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {productData?.map((e, i) => (
                        <TableRow>
                          <TableCell className="!text-center">
                            {i + 1}
                          </TableCell>
                          <TableCell className="!text-center !font-semibold">
                            {e?.title}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.catagory}
                          </TableCell>
                          <TableCell className="!text-center !space-x-2">
                            {e?.keyward?.length === 0 ? (
                              <section>None</section>
                            ) : (
                              e?.keyward?.map((k, i) => <Chip label={k} />)
                            )}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.variant?.length}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.avgRating}
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.images?.length}
                          </TableCell>
                          <TableCell className="!text-center">
                            <PopOverBox
                              trigger={
                                <section className="flex justify-center">
                                  <span className="bg-slate-200/60 hover:bg-slate-200/90 text-slate-800 p-2 rounded-full">
                                    <BsThreeDotsVertical size={"17px"} />
                                  </span>
                                </section>
                              }
                              dataArray={[
                                <Link to={`/staff/product/edit/${e?._id}`}>
                                  <section className="w-[8rem] px-3 py-1 hover:bg-slate-200">
                                    Edit
                                  </section>
                                </Link>,
                                <Link to={`/staff/product/video/${e?._id}`}>
                                  <section className="w-[8rem] px-3 py-1 hover:bg-slate-200">
                                    Videos
                                  </section>
                                </Link>,
                                <section
                                  className="w-[8rem] px-3 py-1 hover:bg-slate-200 cursor-pointer text-red-500 font-medium"
                                  onClick={() =>
                                    handleAlertOpen(e?._id, e?.title)
                                  }
                                >
                                  Delete
                                </section>,
                              ]}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div>
                    <PaginationBar
                      totalTablePage={totalPages}
                      setTablePage={setPage}
                      tablePage={page}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default STAllProducts;
