import React, { useState } from "react";
import signUpImg from "../../assets/signup.svg";
import PasswordField from "../../component/PasswordField";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import ButtonCompo from "../../component/ButtonCompo";
import logo from "../../assets/companyLogo.png";
import { useForm } from "react-hook-form";
import AlertBox from "../../component/AlertBox";
import { Link, useNavigate } from "react-router-dom";
import {
  axiosInstance,
  passwordRegex,
} from "../../constant";
import Loading from "../../component/Loading";
import { useDispatch } from "react-redux";
import { staffLogin } from "../../store/staff.slice";

function STSignin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  async function createAccount(data) {
    try {
      setLoading(true);
      const res = await axiosInstance.post("/api/v1/staff/login", data);
      dispatch(staffLogin({status: true}))
      navigate("/staff/dashboard");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center h-[100vh] bg-[#FFEDD0] flex-col">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        {/* <div className="w-full p-5 bg-orange-200/30 z-[100] flex justify-center items-center">
        <img src={logo} className="w-[10rem]" alt="" />
        </div> */}
        <div className="w-[30rem] h-[30rem] top-[-10rem] z-[1] right-[-10rem] bg-[#FFDB57]/40 fixed rounded-full blur-[10rem]"></div>
        <div className="w-[30rem] h-[30rem] top-[-10rem] z-[1] right-[-10rem] bg-[#FF8000]/20 fixed rounded-full blur-[10rem]"></div>
        <div className="w-full h-full flex justify-center items-center place-items-center gap-3">
          <div className="w-1/2 h-full text-center hidden md:flex justify-center flex-col items-center gradient-custom">
            <h2 className="heading_text text-[40px] text-white translate-x-[-100%] animate-slide-in uppercase font-medium">
              Welcome to
            </h2>
            <h1 className="heading_text text-[50px] font-bold uppercase text-white translate-x-[-100%] animate-slide-out">
              Nutribiosciences
            </h1>
            <p className="heading_text text-[25px] mb-[3rem] w-[40rem] text-white translate-x-[-100%] animate-slide-top">
              "Nutri Bio Science offers natural, plant-based healthcare products
              crafted to enhance lifestyle.
            </p>
            <img
              src={signUpImg}
              className="w-[25rem] translate-x-[-100%] animate-slide-bottom"
              alt=""
            />
          </div>
          <div className="w-1/2 flex items-center justify-center h-full flex-col gap-[2rem] lg:gap-[5rem] z-[10]">
            <div className="w-[95vw] md:w-[25rem]">
              <div className="flex w-full justify-center items-center mb-3">
                <img src={logo} className="w-[10rem]" alt="" />
              </div>
              <h1 className="text-center text-[30px] md:text-[40px] font-semibold text-[#965311]">
                Staff Log In
              </h1>
              <p className="text-center text-orange-800/60 mt-2">
                Please log in to your account
              </p>
              <form className="w-full" onSubmit={handleSubmit(createAccount)}>
                <div className="mt-[3rem] space-y-3">
                  <InputFieldsWithIcon
                    label={"Username"}
                    {...register("username", {
                      required: {
                        value: true,
                        message: "This field is required.",
                      },
                    })}
                    error={errors?.email}
                  />
                  <PasswordField
                    {...register("password", {
                      pattern: {
                        value: passwordRegex,
                        message:
                          "Password should be minimum 8 characters. At least 1 number, 1 capital letter, 1 small letter, 1 special character is required.",
                      },
                      required: {
                        value: true,
                        message: "This field is required.",
                      },
                    })}
                    error={errors?.password}
                  />
                </div>
                <ButtonCompo
                  type="submit"
                  className="!w-full !h-[3rem] !bg-orange-500 !mt-7 !text-white !font-bold"
                >
                  log in
                </ButtonCompo>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default STSignin;
