import React, { useEffect, useState } from "react";
import { axiosInstance, SERVER_URL } from "../constant";
import Heading from "../component/Heading";
import { Link } from "react-router-dom";
import ButtonCompo from "../component/ButtonCompo";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";
import { FiAlertCircle } from "react-icons/fi";
import {
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import AccrodianBox from "../component/AccrodianBox";
import { useSelector } from "react-redux";
import DialogBox from "../component/DialogBox";
import PaymentOnline from "../component/PaymentOnline";
import NotFound from "../component/NotFound";
import AddAddress from "../component/AddAddress";

function CartCard({
  item,
  setReload,
  setOpen,
  setSuccess,
  setMessage,
  setLoading,
}) {
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  useEffect(() => {
    setSelectedQuantity(item?.quantity);
  }, [item]);

  // delete item
  async function deleteItem() {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/cart/remove/${item?._id}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Item deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // edit quantity
  async function editItem(isIncrease) {
    console.log("hi");
    let quantity = selectedQuantity;
    if (isIncrease) {
      quantity = quantity + 1;
    } else {
      quantity = quantity - 1;
    }
    if (quantity <= 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Quantity must be bigger than 0");
      return null;
    }
    const data = {
      value: quantity,
      itemId: item?._id,
    };
    try {
      setLoading(true);
      const res = await axiosInstance.put(`/api/v1/cart/quantity/edit`, data);
      setReload((prev) => !prev);
      setSelectedQuantity(quantity);
      setOpen(true);
      setSuccess(true);
      setMessage("Item edited successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <section className="bg-white p-5 flex gap-5 relative rounded-[5px]">
      <div className="">
        <section className="w-[3rem] md:w-[8rem] md:h-[8rem] h-[3rem] bg-orange-300 rounded-[10px]">
          <img
            src={`${SERVER_URL}/api/v1/file/get/${item.productImage}`}
            className="w-full h-full object-cover"
            alt=""
          />
        </section>
      </div>
      <div className="flex flex-col sm:flex-row justify-between w-full items-end">
        <section className="h-full">
          <Link
            to={`/product/${item?.productId}`}
            target="_blank"
            className="font-semibold hover:text-orange-500"
          >
            {item?.productTitle}{" "}
            <span>
              <Chip label={item?.productCatagory} />
            </span>
          </Link>
          <p className="text-slate-500/80 font-semibold mt-1 text-[15px]">
            {item?.productQuantity}
          </p>
          <p className="mt-1 text-[18px] font-semibold">₹{item?.totalPrice}</p>
          <section className="border mt-3 w-fit flex items-center rounded-[5px]">
            <button className="p-1" onClick={() => editItem(false)}>
              <FaMinus />
            </button>
            <input
              type="text"
              className="py-1 w-[3rem] outline-none text-center"
              value={selectedQuantity}
              readOnly
            />
            <button className="p-1" onClick={() => editItem(true)}>
              <FaPlus />
            </button>
          </section>
        </section>
        <section className="flex items-center justify-center mt-3 sm:mt-0">
          {/* <ButtonCompo
            className="flex gap-[4px] !text-orange-400 !font-semibold !text-[13px]"
            onClick={editItem}
          >
            Save
          </ButtonCompo> */}
          <ButtonCompo
            className="flex gap-[4px] !text-slate-400/90 !font-semibold !text-[13px]"
            onClick={deleteItem}
          >
            <MdDelete size={"18px"} /> Remove
          </ButtonCompo>
        </section>
      </div>
    </section>
  );
}

function CartPage() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [toBePaid, setToBePaid] = useState(0);
  const [allCoupons, setAllCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupn] = useState("");
  const [totalCouponDiscount, setTotalCouponDiscount] = useState(0);
  const [appliedCouponMinimumAmount, setAppliedCouponMinimumAmount] =
    useState("");
  const [couponDiscountType, setCouponDiscountType] = useState("");
  const [appliedCouponDiscount, setAppliedCouponDiscout] = useState(0);
  const [couponError, setCOuponError] = useState("");
  const [allCharges, setAllCharges] = useState([]);
  const [appliedCharges, setAppliedCharges] = useState([]);
  const [userData, setUserData] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [totalChargeAmount, setTotalChargeAmount] = useState(0);
  const [userReload, setUserReload] = useState(false);

  // get cart items
  async function getCartItems() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/api/v1/cart/get/all`);
      const rawData = res.data?.data;
      // console.log(res.data?.data);
      setCartData(rawData);
      let rawTotalPrice = 0;
      rawData?.map((item) => {
        rawTotalPrice =
          rawTotalPrice + Number(item?.totalPrice) * Number(item?.quantity);
      });
      setTotalPrice(rawTotalPrice);
      setToBePaid(rawTotalPrice);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get user
  async function getUser() {
    try {
      setLoading(true);
      const res = await axiosInstance.get("/api/v1/user/get");
      setUserData(res?.data?.data);
    } catch (error) {
      // console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "User not found");
    } finally {
      setLoading(false);
    }
  }

  // fetch coupons
  async function getCoupons() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/api/v1/coupon/user/get/all`);
      const rawData = res.data?.data;
      console.log(res.data?.data);
      setAllCoupons(rawData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // fetch charges
  async function getCharges() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/api/v1/charges/user/get/all`);
      const rawData = res.data?.data;
      console.log(res.data?.data);
      setAllCharges(rawData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // apply coupon
  async function applyCoupon() {
    if (selectedCoupon === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select a coupon");
      return null;
    }
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/v1/coupon/get/single/${selectedCoupon}`
      );
      const rawData = res.data?.data;
      setCouponDiscountType(rawData?.discountType);
      setAppliedCouponMinimumAmount(rawData?.minimumAmount);
      setAppliedCouponDiscout(rawData?.discount);
      console.log(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // coupon calculation
  useEffect(() => {
    if (appliedCouponDiscount > 0) {
      if (appliedCouponMinimumAmount < totalPrice) {
        if (couponDiscountType === "Percentage") {
          const discount =
            Number(totalPrice) -
            Math.ceil(
              Number(appliedCouponDiscount) * (Number(totalPrice) / 100)
            );
          setTotalCouponDiscount(
            Math.ceil(
              Number(appliedCouponDiscount) * (Number(totalPrice) / 100)
            )
          );
          setToBePaid(discount);
          setCOuponError("");
        } else {
          if (totalPrice < appliedCouponDiscount) {
            setOpen(true);
            setSuccess(false);
            setMessage("Total price must be bigger than your total bill");
          } else {
            const discount = Number(totalPrice) - Number(appliedCouponDiscount);
            setTotalCouponDiscount(appliedCouponDiscount);
            setToBePaid(discount);
            setCOuponError("");
          }
        }
      } else {
        setCOuponError(
          `You need ₹${
            Number(appliedCouponMinimumAmount) - Number(totalPrice)
          } more in your bill amount to use this coupon`
        );
        setTotalCouponDiscount(0);
      }
    }
  }, [appliedCouponDiscount, totalPrice, cartData, couponDiscountType]);

  // extra charges calculation
  useEffect(() => {
    let roughAppliedCharges = [];
    let totalCharges = 0;
    allCharges?.forEach((charge) => {
      if (
        charge?.minimumAmount > totalPrice ||
        charge?.maximumAmount < totalPrice
      ) {
        if (charge?.chargeType === "Percentage") {
          const chargePrice = Math.ceil(
            Number(charge?.chargeAmount) * (Number(totalPrice) / 100)
          );
          const chargeData = {
            title: charge?.title,
            amount: chargePrice,
          };
          totalCharges += chargePrice;
          roughAppliedCharges.push(chargeData);
          setToBePaid((prev) => prev + chargePrice);
        } else {
          const chargePrice = Number(charge?.chargeAmount);
          const chargeData = {
            title: charge?.title,
            amount: charge?.chargeAmount,
          };
          totalCharges += chargePrice;
          roughAppliedCharges.push(chargeData);
          setToBePaid((prev) => prev + chargePrice);
        }
      }
    });
    setTotalChargeAmount(totalCharges);
    setAppliedCharges(roughAppliedCharges);
  }, [totalPrice, allCharges, appliedCouponDiscount]);

  // initialize address
  useEffect(() => {
    if (Array.isArray(userData?.address)) {
      setSelectedAddress(userData?.address[0]);
    }
  }, [userData]);

  useEffect(() => {
    getCoupons();
    getCharges();
  }, []);

  useEffect(() => {
    getCartItems();
  }, [reload]);

  useEffect(() => {
    getUser();
  }, [userReload]);

  console.log(userData?.address);

  if (cartData?.length === 0) {
    return (
      <div className="mt-[10rem] space-y-5">
        <Heading>Your cart is empty</Heading>
        <NotFound />
      </div>
    );
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center mt-[7rem]">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>My Cart</Heading>
          <div className="w-full flex flex-col lg:flex-row justify-center mt-[3rem] gap-3">
            <div className="w-full space-y-3">
              {cartData?.map((item) => (
                <CartCard
                  item={item}
                  setMessage={setMessage}
                  setOpen={setOpen}
                  setReload={setReload}
                  setSuccess={setSuccess}
                  setLoading={setLoading}
                />
              ))}
            </div>
            <div className="w-full flex lg:hidden mt-5 justify-center items-center">
              <DialogBox
                trigger={
                  <section className="w-full h-[3rem] bg-[#ff8000] flex justify-center items-center px-5 rounded-[5px] cursor-pointer">
                    <p className="text-white font-semibold">Proceed To Bill</p>
                  </section>
                }
                fullScreen
                className="!z-[10000]"
                title="Bill Summery"
              >
                <div className="w-full bg-white px-2 py-5 rounded-[5px] shadow-md">
                  <p className="text-[18px] font-semibold">Bill Summery</p>
                  <section className="mt-3 space-y-1">
                    {cartData?.map((item) => (
                      <p className="flex justify-between text-slate-500">
                        {item?.productTitle}{" "}
                        <span>
                          ₹{Number(item?.totalPrice) * Number(item?.quantity)}
                        </span>
                      </p>
                    ))}
                    <section className="w-full h-[1px] !mt-5 !mb-5 bg-slate-300"></section>
                    <p className="flex justify-between text-[18px] font-semibold !mt-2">
                      Total <span className="font-semibold">₹{totalPrice}</span>
                    </p>
                    {appliedCharges?.map((charge) => (
                      <p className="flex justify-between !mt-2">
                        {charge?.title}{" "}
                        <span className="">+₹{charge?.amount}</span>
                      </p>
                    ))}
                    {totalCouponDiscount > 0 ? (
                      <p className="flex justify-between text-green-600 !mt-2">
                        Coupon discount <span>-₹{totalCouponDiscount}</span>
                      </p>
                    ) : (
                      ""
                    )}
                    <section>
                      <section className="flex w-full justify-between !mt-5 gap-3">
                        <TextField
                          placeholder="Apply coupon here"
                          className="!w-full"
                          size="medium"
                          onChange={(event) =>
                            setSelectedCoupn(event?.target?.value)
                          }
                        />
                        <ButtonCompo variant={"primary"} onClick={applyCoupon}>
                          Apply
                        </ButtonCompo>
                      </section>
                      {couponError?.length > 0 ? (
                        <p className="mt-2 text-red-600">{couponError}</p>
                      ) : (
                        ""
                      )}
                    </section>
                    <section className="!mt-3">
                      <AccrodianBox title={"Available Coupons"}>
                        <div className="space-y-3">
                          {allCoupons?.map((coupon, i) => (
                            <section>
                              <p className="flex gap-3 items-center">
                                {coupon?.coupon}
                                <Chip
                                  label={
                                    coupon?.discountType === "Percentage"
                                      ? `${coupon?.discount}% off`
                                      : `₹${coupon?.discount} off`
                                  }
                                />
                              </p>
                              <p className="text-[15px] text-slate-500 font-medium flex items-center gap-2 mt-1">
                                <FiAlertCircle size={"18px"} /> Coupon
                                applicable for bill amount above ₹
                                {coupon?.minimumAmount}
                              </p>
                            </section>
                          ))}
                        </div>
                      </AccrodianBox>
                    </section>
                    <section className="w-full h-[1px] !mt-5 !mb-5 bg-slate-300"></section>
                    <p className="flex justify-between text-[18px] font-semibold !mt-2">
                      To Be Paid{" "}
                      <span className="font-semibold">₹{toBePaid}</span>
                    </p>
                    <section className="w-full h-[1px] !mt-5 !mb-5 bg-slate-300"></section>
                    <section>
                      <section className="flex justify-between w-full items-center">
                        <p className="flex justify-between text-[18px] font-semibold !mt-2">
                          Address
                        </p>
                        <DialogBox
                          trigger={
                            userData?.address?.length !== 0 ? (
                              <ButtonCompo>Change Address</ButtonCompo>
                            ) : (
                              ""
                            )
                          }
                          title="Select An Address"
                          closeActionButton="Save"
                          className="!z-[100000]"
                        >
                          <div className="space-y-3">
                            {userData?.address?.length === 0 ? (
                              <div className="flex justify-center items-center flex-col gap-5">
                                <NotFound width="10rem" />
                                <AddAddress setUserReload={setUserReload} />
                              </div>
                            ) : (
                              userData?.address?.map((e, i) => (
                                <section
                                  className={`border p-3 rounded-[10px] cursor-pointer ${
                                    e?.addressId === selectedAddress?.addressId
                                      ? "bg-blue-500 text-white hover:bg-blue-600 border-none"
                                      : "hover:bg-slate-100"
                                  }`}
                                  onClick={() => setSelectedAddress(e)}
                                >
                                  <section className="w-full flex gap-[2rem]">
                                    <p className="font-semibold">
                                      {e?.fullName}
                                    </p>
                                    <p className="font-semibold">
                                      Phone : <span>{e?.phone}</span>
                                    </p>
                                  </section>
                                  <section className="mt-2">
                                    <p className="font-semibold">
                                      City : <span>{e?.city}</span>
                                    </p>
                                    <p className="font-semibold">
                                      State : <span>{e?.state}</span>
                                    </p>
                                  </section>
                                  <p className="mt-2">{`${e?.streetName1}, ${e?.streetName2}, ${e?.pincode}`}</p>
                                </section>
                              ))
                            )}
                          </div>
                        </DialogBox>
                      </section>
                      <div className="mt-3">
                        {userData?.address?.length === 0 ? (
                          <div className="flex flex-col gap-3 justify-center items-center w-full">
                            <p className="text-slate-500">No address found</p>
                            <AddAddress setUserReload={setUserReload} />
                          </div>
                        ) : (
                          <div>
                            <section className="border p-3 rounded-[10px]">
                              <section className="w-full flex gap-[3rem]">
                                <p className="font-semibold">
                                  {selectedAddress?.fullName}
                                </p>
                                <p className="font-semibold">
                                  Phone : <span>{selectedAddress?.phone}</span>
                                </p>
                              </section>
                              <section className="mt-2">
                                <p className="font-semibold">
                                  City : <span>{selectedAddress?.city}</span>
                                </p>
                                <p className="font-semibold">
                                  State : <span>{selectedAddress?.state}</span>
                                </p>
                              </section>
                              <p className="mt-2 text-slate-600">{`${selectedAddress?.streetName1}, ${selectedAddress?.streetName2}, ${selectedAddress?.pincode}`}</p>
                            </section>
                            <div className="w-full flex justify-end mt-3">
                              <AddAddress setUserReload={setUserReload} />
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                    <section className="w-full h-[1px] !mt-5 !mb-5 bg-slate-300"></section>
                    <section>
                      <p className="flex justify-between text-[18px] font-semibold !mt-2">
                        Payment Method
                      </p>
                      <section className="mt-5 px-3 py-3 text-blue-600 rounded-[5px] cursor-pointer hover:bg-slate-200/50">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Credit Card/Debit Card/UPI Payment"
                          />
                        </RadioGroup>
                      </section>
                    </section>
                    <section className="!mt-[2rem]">
                      <PaymentOnline
                        products={cartData}
                        address={selectedAddress}
                        coupon={totalCouponDiscount > 0 ? selectedCoupon : ""}
                        chargeAmount={totalChargeAmount}
                        charges={appliedCharges}
                        discount={totalCouponDiscount}
                        discountType={
                          totalCouponDiscount > 0 ? couponDiscountType : ""
                        }
                        totalPrice={toBePaid}
                      />
                    </section>
                  </section>
                </div>
              </DialogBox>
            </div>

            <div className="w-[50rem] hidden lg:flex flex-col bg-white h-full px-5 py-5 rounded-[5px] shadow-md">
              <p className="text-[18px] font-semibold">Bill Summery</p>
              <section className="mt-3 space-y-1">
                {cartData?.map((item) => (
                  <p className="flex justify-between text-slate-500">
                    {item?.productTitle}{" "}
                    <span>
                      ₹{Number(item?.totalPrice) * Number(item?.quantity)}
                    </span>
                  </p>
                ))}
                <section className="w-full h-[1px] !mt-5 !mb-5 bg-slate-300"></section>
                <p className="flex justify-between text-[18px] font-semibold !mt-2">
                  Total <span className="font-semibold">₹{totalPrice}</span>
                </p>
                {appliedCharges?.map((charge) => (
                  <p className="flex justify-between !mt-2">
                    {charge?.title} <span className="">+₹{charge?.amount}</span>
                  </p>
                ))}
                {totalCouponDiscount > 0 ? (
                  <p className="flex justify-between text-green-600 !mt-2">
                    Coupon discount <span>-₹{totalCouponDiscount}</span>
                  </p>
                ) : (
                  ""
                )}
                <section>
                  <section className="flex w-full justify-between !mt-5 gap-3">
                    <TextField
                      placeholder="Apply coupon here"
                      className="!w-full"
                      size="medium"
                      onChange={(event) =>
                        setSelectedCoupn(event?.target?.value)
                      }
                    />
                    <ButtonCompo variant={"primary"} onClick={applyCoupon}>
                      Apply
                    </ButtonCompo>
                  </section>
                  {couponError?.length > 0 ? (
                    <p className="mt-2 text-red-600">{couponError}</p>
                  ) : (
                    ""
                  )}
                </section>
                <section className="!mt-3">
                  <AccrodianBox title={"Available Coupons"}>
                    <div className="space-y-3">
                      {allCoupons?.map((coupon, i) => (
                        <section>
                          <p className="flex gap-3 items-center">
                            {coupon?.coupon}
                            <Chip
                              label={
                                coupon?.discountType === "Percentage"
                                  ? `${coupon?.discount}% off`
                                  : `₹${coupon?.discount} off`
                              }
                            />
                          </p>
                          <p className="text-[15px] text-slate-500 font-medium flex items-center gap-2 mt-1">
                            <FiAlertCircle size={"18px"} /> Coupon applicable
                            for bill amount above ₹{coupon?.minimumAmount}
                          </p>
                        </section>
                      ))}
                    </div>
                  </AccrodianBox>
                </section>
                <section className="w-full h-[1px] !mt-5 !mb-5 bg-slate-300"></section>
                <p className="flex justify-between text-[18px] font-semibold !mt-2">
                  To Be Paid <span className="font-semibold">₹{toBePaid}</span>
                </p>
                <section className="w-full h-[1px] !mt-5 !mb-5 bg-slate-300"></section>
                <section>
                  <section className="flex justify-between w-full items-center">
                    <p className="flex justify-between text-[18px] font-semibold !mt-2">
                      Address
                    </p>
                    <DialogBox
                      trigger={
                        userData?.address?.length > 0 ? (
                          <ButtonCompo>Change Address</ButtonCompo>
                        ) : (
                          ""
                        )
                      }
                      title="Select An Address"
                      closeActionButton="Save"
                      className="!z-[10000]"
                    >
                      <div className="space-y-3">
                        {userData?.address?.length === 0 ? (
                          <div className="flex justify-center items-center flex-col gap-5">
                            <NotFound width="10rem" />
                            <AddAddress setUserReload={setUserReload} />
                          </div>
                        ) : (
                          userData?.address?.map((e, i) => (
                            <section
                              className={`border p-3 rounded-[10px] cursor-pointer ${
                                e?.addressId === selectedAddress?.addressId
                                  ? "bg-blue-500 text-white hover:bg-blue-600 border-none"
                                  : "hover:bg-slate-100"
                              }`}
                              onClick={() => setSelectedAddress(e)}
                            >
                              <section className="w-full flex gap-[3rem]">
                                <p className="font-semibold">{e?.fullName}</p>
                                <p className="font-semibold">
                                  Phone : <span>{e?.phone}</span>
                                </p>
                              </section>
                              <section className="mt-2">
                                <p className="font-semibold">
                                  City : <span>{e?.city}</span>
                                </p>
                                <p className="font-semibold">
                                  State : <span>{e?.state}</span>
                                </p>
                              </section>
                              <p className="mt-2">{`${e?.streetName1}, ${e?.streetName2}, ${e?.pincode}`}</p>
                            </section>
                          ))
                        )}
                      </div>
                    </DialogBox>
                  </section>
                  <div className="mt-3">
                    {userData?.address?.length === 0 ? (
                      <div className="flex flex-col gap-3 justify-center items-center w-full">
                        <p className="text-slate-500">No address found</p>
                        <AddAddress setUserReload={setUserReload} />
                      </div>
                    ) : (
                      <div>
                        <section className="border p-3 rounded-[10px]">
                          <section className="w-full flex gap-[3rem]">
                            <p className="font-semibold">
                              {selectedAddress?.fullName}
                            </p>
                            <p className="font-semibold">
                              Phone : <span>{selectedAddress?.phone}</span>
                            </p>
                          </section>
                          <section className="mt-2">
                            <p className="font-semibold">
                              City : <span>{selectedAddress?.city}</span>
                            </p>
                            <p className="font-semibold">
                              State : <span>{selectedAddress?.state}</span>
                            </p>
                          </section>
                          <p className="mt-2 text-slate-600">{`${selectedAddress?.streetName1}, ${selectedAddress?.streetName2}, ${selectedAddress?.pincode}`}</p>
                        </section>
                        <div className="w-full flex justify-end mt-3">
                          <AddAddress setUserReload={setUserReload} />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section className="w-full h-[1px] !mt-5 !mb-5 bg-slate-300"></section>
                <section>
                  <p className="flex justify-between text-[18px] font-semibold !mt-2">
                    Payment Method
                  </p>
                  <section className="mt-5 px-3 py-3 text-blue-600 rounded-[5px] cursor-pointer hover:bg-slate-200/50">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Credit Card/Debit Card/UPI Payment"
                      />
                    </RadioGroup>
                  </section>
                </section>
                <section className="!mt-[2rem]">
                  <PaymentOnline
                    products={cartData}
                    address={selectedAddress}
                    coupon={totalCouponDiscount > 0 ? selectedCoupon : ""}
                    chargeAmount={totalChargeAmount}
                    charges={appliedCharges}
                    discount={totalCouponDiscount}
                    discountType={
                      totalCouponDiscount > 0 ? couponDiscountType : ""
                    }
                    totalPrice={toBePaid}
                  />
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartPage;
