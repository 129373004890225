import React from "react";
import { SERVER_URL } from "../constant";
import { Link } from "react-router-dom";

function CatagoryCard({ title, url, index }) {
  return (
    <div className="flex justify-center items-center flex-col gap-3">
      <Link to={`/shop?catagory=${title}`}>
        <section
          className={`bg-[#ffedd04f] border-[#FF8000] border-[1px] w-[4rem] lg:w-[6rem] lg:h-[6rem] h-[4rem] rounded-[10px] overflow-visible flex justify-center items-center catagory-card-shadow-hover`}
        >
          <img
            src={`${SERVER_URL}/api/v1/file/get/${url}`}
            className="w-[8rem] h-auto object-cover"
            alt=""
            loading="lazy"
          />
        </section>
      </Link>
      <p className="text-orange-800 font-semibold">{title}</p>
    </div>
  );
}

export default CatagoryCard;
