import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";
import NotFound from "../component/NotFound";
import Loading from "../component/Loading";
import AlertBox from "../component/AlertBox";
import { axiosInstance, SERVER_URL } from "../constant";

function SingleOrder() {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState({});
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [notFOund, setNotfound] = useState(false);
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState("");
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  // get order
  async function getOrder() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/v1/payment/get/single/order/user/${orderId}`
      );
      const rawData = res.data?.data;
      console.log(res.data)
      setOrderData(rawData);
      setSelectedDeliveryStatus(rawData?.delivaryStatus);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrder();
  }, []);

  if (notFOund) {
    return (
      <div>
        <NotFound />
      </div>
    );
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center py-5 mt-[5rem]">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[100vw] lg:w-[50rem] p-5 rounded-[5px] shadow-md bg-white">
          <section>
            <h1 className="text-[20px] font-medium">
              Order ID: {orderData?.orderNumber}
            </h1>
            <p className="mt-2 text-slate-500">
              {new Date(orderData?.date).toLocaleDateString()}
            </p>
          </section>
          <div className="mt-[1rem] border rounded-[10px] p-5">
            <p className="font-medium">Ordered Items</p>
            <div className="mt-5 space-y-3">
              {orderData?.products?.map((product, i) => (
                <section className="flex justify-start items-start gap-5">
                  <section className="w-[7rem] h-[7rem] rounded-[10px] bg-slate-100">
                    <img
                      src={`${SERVER_URL}/api/v1/file/get/${product?.productImage}`}
                      className="w-full h-full object-cover"
                      alt=""
                    />
                  </section>
                  <section>
                    <p className="text-[15px] text-slate-500">
                      {product?.productCatagory}
                    </p>
                    <p className="font-medium">{`${product?.productTitle}, ${product?.productQuantity}`}</p>
                    <p className="text-[15px] text-slate-500">
                      Qty: <span>{product?.quantity}</span>
                    </p>
                    <p className="text-[19px] mt-3 font-medium">
                      ₹{product?.totalPrice}
                    </p>
                  </section>
                </section>
              ))}
            </div>
          </div>
          <div className="mt-[1rem] border rounded-[10px] p-5">
            <p className="font-medium">Order Info</p>
            <div className="mt-5">
              <p className="flex justify-start items-center gap-3 font-medium">
                Delivary Status: {orderData?.delivaryStatus}
              </p>
              <p className="flex justify-start gap-3 font-medium mt-1">
                Payment Status:{" "}
                <span className="text-slate-700">
                  {orderData?.paymentStatus === "success" ? (
                    <span className="text-green-600 font-medium">Success</span>
                  ) : (
                    <span className="text-red-600 font-medium">Failed</span>
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-[1rem] border rounded-[10px] p-5">
            <p className="font-medium">Shipping Address</p>
            <div className="mt-5">
              <p className="flex justify-start gap-3 font-medium">
                Name:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.fullName}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Phone Number:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.phone}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Address:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.streetName1 +
                    ", " +
                    orderData?.address?.streetName2}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                Pincode:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.pincode}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                City:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.city}
                </span>
              </p>
              <p className="flex justify-start gap-3 font-medium">
                State:{" "}
                <span className="text-slate-700">
                  {orderData?.address?.state}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-[1rem] border rounded-[10px] p-5">
            <p className="font-medium">Order Summery</p>
            <div className="mt-5 overflow-auto">
              <Table>
                <TableHead>
                  <TableRow className="bg-slate-100">
                    <TableCell className="!text-center">Sl No.</TableCell>
                    <TableCell className="!text-left">Item</TableCell>
                    <TableCell className="!text-center">Quantity</TableCell>
                    <TableCell className="!text-center">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderData?.products?.map((product, i) => (
                    <TableRow>
                      <TableCell className="!text-center">{i + 1}</TableCell>
                      <TableCell className="!text-left">{`${product?.productTitle}, ${product?.productQuantity}`}</TableCell>
                      <TableCell className="!text-center">
                        {product?.quantity}
                      </TableCell>
                      <TableCell className="!text-center">
                        ₹{product?.totalPrice}
                      </TableCell>
                    </TableRow>
                  ))}
                  {orderData?.discount && (
                    <TableRow>
                      <TableCell className="!text-center" colSpan={1}>
                        Total Discount
                      </TableCell>
                      <TableCell className="!text-left" colSpan={2}></TableCell>
                      <TableCell className="!text-center">
                        -₹{orderData?.discount}
                      </TableCell>
                    </TableRow>
                  )}
                  {orderData?.charges &&
                    orderData?.charges?.map((charge, i) => (
                      <TableRow>
                        <TableCell className="!text-center" colSpan={1}>
                          {charge?.title}
                        </TableCell>
                        <TableCell
                          className="!text-left"
                          colSpan={2}
                        ></TableCell>
                        <TableCell className="!text-center">
                          +₹{charge?.amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell className="!text-center" colSpan={1}>
                      Total
                    </TableCell>
                    <TableCell className="!text-left" colSpan={2}></TableCell>
                    <TableCell className="!text-center">
                      <span className="text-[20px] font-semibold text-green-600">₹{orderData?.totalPrice}</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
          {orderData?.invoice !== undefined &&
          orderData?.invoice?.length > 0 && orderData?.delivaryStatus === "Delivered" ? (
            <Link to={orderData?.invoice} target="_blank">
              <div className="mt-5 px-5 py-3 border rounded-[10px] cursor-pointer hover:bg-slate-100 flex justify-between items-center">
                <p>Download Invoice</p>
                <IoIosArrowForward size={"18px"} />
              </div>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default SingleOrder;
