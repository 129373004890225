import { Box, Rating } from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";

function RatingCompo({value, setValue, isCaptionOn=false, ...props}) {
  const [hover, setHover] = React.useState(-1);
  const labels = {
    1: "Useless",
    2: "Poor",
    3: "Ok",
    4: "Good",
    5: "Excellent",
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  return (
    <div className="">
      <Rating
        name="hover-feedback"
        value={value}
        {...props}
        precision={1}
        getLabelText={getLabelText}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {value !== null && isCaptionOn === true && (
        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
      )}
    </div>
  );
}

export default RatingCompo;