import { InputAdornment, Popover, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import ButtonCompo from "./ButtonCompo";
import Loading from "./Loading";
import { axiosInstance, SERVER_URL } from "../constant";
import { Link } from "react-router-dom";

function SearchCompo() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [message, setMessage] = useState("Please search something");
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [adjustHeight, setAdjustHeight] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //--------------------Search products------------------------

  async function searchProduct() {
    if (searchQuery?.length <= 1) return null;
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/get/all/1/10?search=${searchQuery}`
      );
      console.log(res.data?.data);
      if (res.data?.data?.data === undefined) {
        setMessage("No result found");
        setProductData([])
      } else {
        setProductData(res.data?.data?.data);
      }
    } catch (error) {
      setMessage("No result found");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // scroll
  function handleScroll() {
    if (window.scrollY > 0) {
      setAdjustHeight(true);
    } else {
      setAdjustHeight(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(productData);

  return (
    <div>
      {loading && <Loading />}
      <TextField
        placeholder="Search Products"
        className="!bg-white !rounded-full w-[10rem] md:w-[25rem]"
        aria-describedby={id}
        onClick={handleClick}
        onChange={(event) => setSearchQuery(event?.target?.value)}
        sx={{
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "none", // Disable the border
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "25px", // Add rounded corners
            height: "3rem",
          },
        }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position={"start"}>
                <IoSearch className="mr-2 text-slate-500" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ButtonCompo
                  className="!bg-[#FF8000] !rounded-[23px] !text-[12px] !px-3 !text-white !font-semibold"
                  onClick={searchProduct}
                >
                  Search
                </ButtonCompo>
              </InputAdornment>
            ),
          },
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={`${adjustHeight === true ? "!mt-8" : "!mt-2"}`}
        disableAutoFocus
        disableEnforceFocus
      >
        <div className="min-w-[30rem] px-2 py-2 min-h-[10rem]">
          {productData?.length === 0 ? (
            <p className="text-slate-400 font-semibold mt-[3rem] text-center">
              {message}
            </p>
          ) : (
            <div className="w-full">
              {productData?.map((product, i) => (
                <Link to={`/product/${product?._id}`}>
                  <section className="flex items-start gap-5 hover:bg-slate-100 px-3 py-2 rounded-[10px]" onClick={handleClose}>
                    <section>
                      <div className="w-[3rem] h-[3rem] bg-orange-200/70 rounded-[7px]">
                        <img
                          src={`${SERVER_URL}/api/v1/file/get/${product?.images[0]}`}
                          className="w-full h-full object-contain"
                          alt=""
                        />
                      </div>
                    </section>
                    <section>
                      <div className="flex items-center gap-2">
                        <p className="font-semibold">{product?.title}</p>
                        <p className="text-[13px] bg-orange-400 w-fit px-3 py-[2px] rounded-full text-white font-semibold">
                          {product?.catagory}
                        </p>
                      </div>
                      <p className="text-slate-500 text-[13px] mt-1">
                        See the product
                      </p>
                    </section>
                  </section>
                </Link>
              ))}
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
}

export default SearchCompo;
