import React, { useEffect, useRef, useState } from "react";
import contactUsImg from "../assets/contact-us.jpg";
import { axiosInstance } from "../constant";
import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import InputFieldsWithIcon from "../component/InputFieldsWithIcon";
import ButtonCompo from "../component/ButtonCompo";
import faqImg from "../assets/faqImg.png";
import AccrodianBox from "../component/AccrodianBox";
import Heading from "../component/Heading";
import { useForm } from "react-hook-form";
import DialogBox from "../component/DialogBox";

function ContactUs() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [conatactData, setContactData] = useState({});
  const alertRef = useRef(null);
  const [reload, setReload] = useState(false);

  const handleAlertOpen = () => {
    if (alertRef.current) {
      alertRef.current.handleOpen();
    }
  };

  // form hook
  const { register, handleSubmit } = useForm();

  // fetch conatact
  async function getContact() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/api/v1/company/get/data`);
      const rawData = res.data?.data;
      setContactData(rawData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add msg
  async function addMsg(data) {
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/api/v1/contact/add`, data);
      const rawData = res.data?.data;
      setReload((prev) => !prev);
      handleAlertOpen();
      setOpen(true);
      setSuccess(true);
      setMessage("Message has been sent");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getContact();
  }, [reload]);

  return (
    <>
      {loading && <Loading />}
      <DialogBox ref={alertRef} closeActionButton={"okay"}>
        <div>
          <p>
            We have recived your inquiry. One of our support representive will
            connect you shortly.
          </p>
        </div>
      </DialogBox>
      <div className="w-full flex justify-center items-center flex-col">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-full flex justify-center items-center flex-col">
          <div
            className="w-full h-[35rem] bg-center flex justify-center items-center px-5"
            style={{
              backgroundImage: `url("${contactUsImg}")`,
              backgroundRepeat: "no-repeat",
            }}
          >
            <section className="text-center">
              <h1 className="text-center heading_text uppercase text-[40px] font-extrabold tracking-wide text-[#ff8000]">
                Contact Us
              </h1>
              <p className="text-[18px] text-[#5f32049d]">
                Fuel your curiosity for plant-based nutrition, stress
                management, self-care, and so much more
              </p>
            </section>
          </div>
        </div>
        <div className="w-[90vw] mt-[5rem]">
          <div className="w-full flex flex-col md:flex-row justify-between items-start gap-5">
            <section className="w-[90vw] md:w-[50%]">
              <h1 className="heading_text text-[40px]">Get In Touch</h1>
              <p className="text-slate-500 mt-2">
                Nutri Bioscience, Reach out to us via the form below, Email, or
                Phone, and our team will respond promptly. Your Health and
                satisfaction are our priorities!
              </p>
              <section className="mt-5 space-y-3">
                <div>
                  <section className="flex items-start gap-3">
                    <div>
                      <span className="bg-[#FF8000] p-[5px] rounded-full text-white flex">
                        <FaLocationDot />
                      </span>
                    </div>
                    <div>
                      <h3 className="font-semibold text-[18px]">Address</h3>
                      <p>{conatactData?.address}</p>
                    </div>
                  </section>
                </div>
                <div>
                  <section className="flex items-start gap-3">
                    <div>
                      <span className="bg-[#FF8000] p-[5px] rounded-full text-white flex">
                        <FaPhone />
                      </span>
                    </div>
                    <div>
                      <h3 className="font-semibold text-[18px]">Phone</h3>
                      <p>{conatactData?.contact}</p>
                    </div>
                  </section>
                </div>
                <div>
                  <section className="flex items-start gap-3">
                    <div>
                      <span className="bg-[#FF8000] p-[5px] rounded-full text-white flex">
                        <MdEmail />
                      </span>
                    </div>
                    <div>
                      <h3 className="font-semibold text-[18px]">Email</h3>
                      <p>{conatactData?.email}</p>
                    </div>
                  </section>
                </div>
              </section>
            </section>
            <section className="w-[90vw] md:w-[40rem] flex justify-center items-center bg-[#FFEDD0] py-5 px-3 md:px-[5rem] flex-col rounded-[10px]">
              <h2 className="text-[#FF8000] text-[25px] font-semibold">
                Send A Message
              </h2>
              <form className="w-full" onSubmit={handleSubmit(addMsg)}>
                <div className="w-full space-y-3 mt-[3rem]">
                  <InputFieldsWithIcon label="Name" {...register("name")} />
                  <InputFieldsWithIcon
                    label="Email ID"
                    {...register("email")}
                  />
                  <InputFieldsWithIcon
                    label="Phone Number"
                    {...register("phone")}
                  />
                  <InputFieldsWithIcon
                    label="Message"
                    multiline
                    rows={5}
                    {...register("msg")}
                  />
                </div>
                <section className="flex justify-center items-center mt-5">
                  <ButtonCompo type="submit" variant={"primary"}>
                    Send
                  </ButtonCompo>
                </section>
              </form>
            </section>
          </div>
          <div className="flex w-full flex-col md:flex-row justify-between items-center mt-[5rem] gap-5">
            <section className="w-[90vw] md:w-[50%]">
              <section className="mb-[3rem]">
                <Heading>Frequently Asked Questions</Heading>
              </section>
              <AccrodianBox
                title={
                  "1. WHO SHOULD I CONTACT FOR QUERIES/CONCERNS RELATED TO PRODUCTS OR DELIVERY?"
                }
                defaultExpanded
              >
                <div className="w-full">
                  <p className="text-slate-500">
                    You will be sent an order confirmation email along with your
                    tracking details once dispatched. However, if you have any
                    concerns over your order status, please feel free to reach
                    out to us at nbsonline11@gmail.comand our customer support
                    team will be happy to help you!
                  </p>
                </div>
              </AccrodianBox>
              <AccrodianBox
                title={
                  "2. WHAT TO DO IF MY PAYMENT HAS GONE THROUGH BUT I HAVEN’T RECEIVED AN ORDER CONFIRMATION EMAIL?"
                }
              >
                <div className="w-full">
                  <p className="text-slate-500">
                    In case your payment has gone through, please check your
                    spam folder for an order confirmation email. If you do not
                    find the email, please reach out to us at
                     <span className="text-slate-700 font-medium ml-1">nbsonline11@gmail.com</span> and our customer support team will be
                    happy to help you!
                  </p>
                </div>
              </AccrodianBox>
              <AccrodianBox
                title={"3. WHAT IS THE REFUND AND RETURN POLICY AT NBS?"}
              >
                <div className="w-full">
                  <p className="text-slate-500">
                    It is important to keep in mind that as a company in the
                    food industry, we have strict return/refund policies. So
                    please go through our return/refund policy here. If you feel
                    like you need more assistance, please feel free to drop in
                    an email to nbsonline11@gmail.com 🙂
                  </p>
                </div>
              </AccrodianBox>
            </section>
            <section className="w-[90vw] md:w-[50%] flex justify-center">
              <img src={faqImg} className="w-[70%]" alt="" />
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
