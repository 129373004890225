import React from 'react'

function Blog() {
  return (
    <div>
      Blog
    </div>
  )
}

export default Blog
