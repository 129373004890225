import React, { useEffect, useRef, useState } from "react";
import { axiosInstance, SERVER_URL } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { BsCameraFill } from "react-icons/bs";
import InputFieldsWithIcon from "../component/InputFieldsWithIcon";
import ButtonCompo from "../component/ButtonCompo";
import SelectField from "../component/SelectField";
import { reloadToggle } from "../store/reload.slice";
import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";
import DialogBox from "../component/DialogBox";
import { FaSquarePlus } from "react-icons/fa6";
import notFound from "../assets/notFound.svg";
import { MdEditSquare } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import {
  Checkbox,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  styled,
} from "@mui/material";
import PopOverBox from "../component/PopoverBox";
import { Link, useSearchParams } from "react-router-dom";
import { IoMdArrowDroprightCircle } from "react-icons/io";
import Heading from "../component/Heading";

function Profile({ setReloadDIs }) {
  const userData = useSelector((state) => state.auth?.userData);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);

  // gender array
  const genderArray = [
    {
      title: "Male",
      value: "Male",
    },
    {
      title: "Female",
      value: "Female",
    },
  ];

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFile(event?.target?.files[0]);
    setEnableSaveButton(true);
    if (file) {
      // Create a preview URL for the selected image
      const previewUrl = URL.createObjectURL(file);
      setSelectedImage(previewUrl);
    }
  };

  async function updateProfile() {
    const formData = new FormData();
    formData.append("profileImage", file);
    const keys = ["fullName", "email", "phone", "country", "gender"];
    const values = [fullName, email, phone, country, gender];

    keys.forEach((key, index) => {
      const value = values[index];
      if (value?.length > 0) {
        formData.append(key, value); // Append meaningful key-value pairs
      }
    });
    try {
      setLoading(true);
      const res = await axiosInstance.put("/api/v1/user/update", formData);
      setReloadDIs((prev) => !prev);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setFullName(userData?.fullName || "");
    setEmail(userData?.email || "");
    setPhone(userData?.phone || "");
    setCountry(userData?.country || "");
    setGender(userData?.gender || "");
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="flex w-full justify-center items-center">
          <input
            onChange={handleImageChange}
            type="file"
            className="hidden"
            ref={fileRef}
          />
          <section className="w-[8rem] h-[8rem] flex justify-center items-center">
            <span
              className="bg-[#FF8000] p-2 absolute rounded-full mt-[5rem] ml-[5rem] cursor-pointer"
              onClick={() => fileRef.current.click()}
            >
              <BsCameraFill className="text-white" />
            </span>
            <img
              src={
                selectedImage ||
                `${SERVER_URL}/api/v1/file/get/${userData?.image}`
              }
              alt=""
              className="object-cover w-full h-full rounded-full"
            />
          </section>
        </div>
        <div className="w-full">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
            <InputFieldsWithIcon
              label="Name"
              value={fullName}
              onChange={(event) => {
                setFullName(event?.target?.value);
                setEnableSaveButton(true);
              }}
            />
            <InputFieldsWithIcon
              label="Email"
              value={email}
              onChange={(event) => {
                setEmail(event?.target?.value);
                setEnableSaveButton(true);
              }}
            />
            <InputFieldsWithIcon
              label="Phone Number"
              value={phone}
              onChange={(event) => {
                setPhone(event?.target?.value);
                setEnableSaveButton(true);
              }}
            />
            <InputFieldsWithIcon
              label="Country"
              value={country}
              onChange={(event) => {
                setCountry(event?.target?.value);
                setEnableSaveButton(true);
              }}
            />
            <SelectField
              label={"Gender"}
              value={gender}
              valueArray={genderArray}
              setValue={(event) => {
                setGender(event?.target?.value);
                setEnableSaveButton(true);
              }}
            />
          </div>
          {enableSaveButton === true ? (
            <div className="w-full flex justify-end">
              <ButtonCompo onClick={updateProfile} variant={"primary"}>
                Save Changes
              </ButtonCompo>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

function Address({ setReloadDis }) {
  const userData = useSelector((state) => state.auth?.userData);
  const [streetA1, setStreetA1] = useState("");
  const [streetA2, setStreetA2] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // add address
  async function addAddress() {
    if (
      [streetA1, pincode, state, city, streetA2, fullName, phone].some(
        (item) => item?.length === 0
      )
    ) {
      setOpen(true);
      setSuccess(false);
      setMessage("All fields are required");
      return null;
    }
    if (pincode?.length < 6) {
      setOpen(true);
      setSuccess(false);
      setMessage("Pincode Invalid");
      return null;
    }
    const data = {
      streetAddress1: streetA1,
      streetAddress2: streetA2,
      city: city,
      pincode: pincode,
      state: state,
      phone: phone,
      fullName: fullName,
    };
    try {
      setLoading(true);
      const res = await axiosInstance.post("/api/v1/user/address/add", data);
      setReloadDis((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Address added successsfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
      setFullName("");
      setPhone("");
      setStreetA1("");
      setStreetA2("");
      setPincode("");
      setCity("");
      setState("");
    }
  }

  // edit address
  async function editAddress(addressId) {
    if (pincode?.length < 6) {
      setOpen(true);
      setSuccess(false);
      setMessage("Pincode Invalid");
      return null;
    }
    const data = {
      streetName1: streetA1,
      streetName2: streetA2,
      city: city,
      pincode: pincode,
      state: state,
      phone: phone,
      fullName: fullName,
      addressId: addressId,
    };
    console.log(data);
    try {
      setLoading(true);
      const res = await axiosInstance.put("/api/v1/user/address/edit", data);
      setReloadDis((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Address updated successsfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
      setFullName("");
      setPhone("");
      setStreetA1("");
      setStreetA2("");
      setPincode("");
      setCity("");
      setState("");
    }
  }

  // delete address
  async function deleteAddress(addressId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/user/address/delete/${addressId}`
      );
      setReloadDis((prev) => !prev);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    }
  }

  // it is my address
  async function myAddress(check) {
    if (check) {
      setFullName(userData?.fullName);
      setPhone(userData?.phone);
    } else {
      setFullName("");
      setPhone("");
    }
  }

  // set value for edit
  function setValue(data) {
    setFullName(data?.fullName);
    setPhone(data?.phone);
    setStreetA1(data?.streetName1);
    setStreetA2(data?.streetName2);
    setPincode(data?.pincode);
    setCity(data?.city);
    setState(data?.state);
  }

  // console.log(userData);
  return (
    <>
      {loading && <Loading />}
      <div className="w-full">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-full flex justify-start">
          <DialogBox
            trigger={
              <ButtonCompo className="flex gap-2 !bg-white !text-[#FF8000] !px-5 !py-2 !font-semibold !items-center shadow-md">
                New Address <FaSquarePlus size={"18px"} />
              </ButtonCompo>
            }
            actionButton={<ButtonCompo onClick={addAddress}>Save</ButtonCompo>}
            title="Add A New Address"
            maxWidth="lg"
            className="!z-[10000]"
          >
            <div className="w-full space-y-3">
              <InputFieldsWithIcon
                label="Name"
                isImp={true}
                value={fullName}
                onChange={(event) => setFullName(event?.target?.value)}
                size="small"
              />
              <InputFieldsWithIcon
                label="Phone Number"
                isImp={true}
                value={phone}
                onChange={(event) => setPhone(event?.target?.value)}
                size="small"
              />
              <section className="flex items-center gap-2">
                <Checkbox
                  sx={{
                    padding: 0, // Removes padding from the checkbox itself
                    color: "#FF8000",
                    "&.Mui-checked": {
                      color: "#FF8000",
                    },
                  }}
                  onChange={(event) => myAddress(event?.target?.checked)}
                />
                <p className="text-slate-800 text-[16px]">It's my address</p>
              </section>
              <InputFieldsWithIcon
                label="Street Address 1"
                isImp={true}
                value={streetA1}
                onChange={(event) => setStreetA1(event?.target?.value)}
                size="small"
              />
              <InputFieldsWithIcon
                label="Street Address 2"
                isImp={true}
                value={streetA2}
                onChange={(event) => setStreetA2(event?.target?.value)}
                size="small"
              />
              <InputFieldsWithIcon
                label="Pincode"
                isImp={true}
                value={pincode}
                onChange={(event) => setPincode(event?.target?.value)}
                size="small"
              />
              <InputFieldsWithIcon
                label="City"
                isImp={true}
                value={city}
                onChange={(event) => setCity(event?.target?.value)}
                size="small"
              />
              <InputFieldsWithIcon
                label="State"
                value={state}
                isImp={true}
                onChange={(event) => setState(event?.target?.value)}
                size="small"
              />
            </div>
          </DialogBox>
        </div>
        <div>
          {userData?.address?.length === 0 ? (
            <div className="w-full flex justify-center items-center flex-col gap-3">
              <img src={notFound} className="w-[20rem]" alt="" />
              <p className="text-center text-slate-500 text-[20px]">
                No address found
              </p>
            </div>
          ) : (
            <div className="w-full space-y-3 mt-5">
              {userData?.address?.map((e, i) => (
                <section className="w-[90vw] md:w-[45rem] bg-white px-5 py-5 rounded-[5px] shadow-md space-y-2">
                  <div className="flex w-full justify-between">
                    <section className="flex gap-[3rem]">
                      <p className="font-semibold text-[16px]">{e?.fullName}</p>
                      <p className="font-semibold text-[16px]">
                        Phone : <span>{e?.phone}</span>
                      </p>
                    </section>
                  </div>
                  <section>
                    <p className="font-semibold text-[15px]">
                      Pincode : <span>{e?.pincode}</span>
                    </p>
                    <p className="font-semibold text-[15px]">
                      City : <span>{e?.city}</span>
                    </p>
                    <p className="font-semibold text-[15px]">
                      State : <span>{e?.state}</span>
                    </p>
                  </section>
                  <div className="w-full text-slate-500 text-[15px]">
                    {`${e?.streetName1}, ${e?.streetName2}`}
                  </div>
                  <div className="w-full flex justify-end gap-3">
                    <DialogBox
                      trigger={
                        <ButtonCompo
                          className="flex gap-[4px] !text-slate-400/90 !font-semibold !text-[13px]"
                          onClick={() => setValue(e)}
                        >
                          <MdEditSquare size={"18px"} /> Edit
                        </ButtonCompo>
                      }
                      maxWidth="sm"
                      title="Edit Address"
                      actionButton={
                        <ButtonCompo onClick={() => editAddress(e?.addressId)}>
                          Save Changes
                        </ButtonCompo>
                      }
                      className="!z-[10000]"
                    >
                      <div className="w-full space-y-3">
                        <InputFieldsWithIcon
                          label="Name"
                          isImp={true}
                          value={fullName}
                          onChange={(event) =>
                            setFullName(event?.target?.value)
                          }
                          size="small"
                        />
                        <InputFieldsWithIcon
                          label="Phone Number"
                          isImp={true}
                          value={phone}
                          onChange={(event) => setPhone(event?.target?.value)}
                          size="small"
                        />
                        <section className="flex items-center gap-2">
                          <Checkbox
                            sx={{
                              padding: 0, // Removes padding from the checkbox itself
                              color: "#FF8000",
                              "&.Mui-checked": {
                                color: "#FF8000",
                              },
                            }}
                            onChange={(event) =>
                              myAddress(event?.target?.checked)
                            }
                          />
                          <p className="text-slate-800 text-[16px]">
                            It's my address
                          </p>
                        </section>
                        <InputFieldsWithIcon
                          label="Street Address 1"
                          isImp={true}
                          value={streetA1}
                          onChange={(event) =>
                            setStreetA1(event?.target?.value)
                          }
                          size="small"
                        />
                        <InputFieldsWithIcon
                          label="Street Address 2"
                          value={streetA2}
                          onChange={(event) =>
                            setStreetA2(event?.target?.value)
                          }
                          size="small"
                        />
                        <InputFieldsWithIcon
                          label="Pincode"
                          isImp={true}
                          value={pincode}
                          onChange={(event) => setPincode(event?.target?.value)}
                          size="small"
                        />
                        <InputFieldsWithIcon
                          label="City"
                          isImp={true}
                          value={city}
                          onChange={(event) => setCity(event?.target?.value)}
                          size="small"
                        />
                        <InputFieldsWithIcon
                          label="State"
                          value={state}
                          isImp={true}
                          onChange={(event) => setState(event?.target?.value)}
                          size="small"
                        />
                      </div>
                    </DialogBox>
                    <ButtonCompo
                      className="flex gap-[4px] !text-slate-400/90 !font-semibold !text-[13px]"
                      onClick={() => deleteAddress(e?.addressId)}
                    >
                      <MdDelete size={"18px"} /> Remove
                    </ButtonCompo>
                  </div>
                </section>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function Coupons() {
  return (
    <div className="w-full flex justify-center items-center">
      <Heading>Coming Soon...</Heading>
    </div>
  );
}

function Orders() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);

  // get orders
  async function getOrders() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/api/v1/payment/my-orders`);
      console.log(res.data?.data);
      setOrderData(res.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(`${error?.response?.data?.message}. Please refresh again.`);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw] md:w-[50vw]">
          <div className="flex w-full flex-col gap-3">
            {orderData?.map((order, i) => (
              <Link to={`/order/get/${order?._id}`}>
                <section className="flex justify-between items-center bg-white px-3 py-5 rounded-[5px] shadow-md hover:bg-slate-100">
                  <p className="text-[13px] sm:text-[15px]">
                    {order?.products?.length} items ordered{" "}
                    <span className="text-[12px] sm:text-[15px] bg-slate-200 px-3 py-1 rounded-full font-medium text-slate-700 ml-2">
                      {new Date(order?.date).toLocaleDateString()}
                    </span>
                  </p>
                  <p className="flex flex-col text-[12px] items-center gap-2 text-slate-500">
                    <span className="hidden sm:flex">See More{" "}</span>
                    <IoMdArrowDroprightCircle
                      className="text-slate-400"
                      size={"18px"}
                    />
                  </p>
                </section>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function Subscriptions() {
  return (
    <div className="w-full flex justify-center items-center">
      <Heading>Coming Soon...</Heading>
    </div>
  );
}

function Rewards() {
  return (
    <div className="w-full flex justify-center items-center">
      <Heading>Coming Soon...</Heading>
    </div>
  );
}

function MyProfile() {
  const [page, setPage] = useState("My Profile");
  const navOptions = ["My Profile", "Address Details", "My Orders"];
  const dispatch = useDispatch();
  const reduxReload = useSelector((state) => state.reload?.reload);
  const [reloadDis, setReloadDis] = useState(undefined);
  const [searchParams] = useSearchParams();

  const queryPage = searchParams.get("page");

  useEffect(() => {
    if (queryPage === "address") {
      setPage("Address Details");
    }
  }, []);

  useEffect(() => {
    if (reloadDis !== undefined) {
      dispatch(reloadToggle());
    }
  }, [reloadDis]);

  return (
    <div className="w-full justify-center items-center flex mt-[7rem]">
      <div className="w-[30rem] h-[30rem] top-[-19rem] z-[-1] right-[23rem] bg-[#FFDB57]/40 fixed rounded-full blur-[10rem]"></div>
      <div className="w-[30rem] h-[30rem] top-[-19rem] z-[-1] right-[23rem] bg-[#FF8000]/20 fixed rounded-full blur-[10rem]"></div>
      <div className="w-[30rem] h-[30rem] top-[2rem] z-[-1] right-[-13rem] bg-[#FFDB57]/40 fixed rounded-full blur-[20rem]"></div>
      <div className="w-[30rem] h-[30rem] top-[2rem] z-[-1] right-[-13rem] bg-[#FF8000]/20 fixed rounded-full blur-[20rem]"></div>
      <div className="w-[30rem] h-[30rem] top-[-10rem] z-[-1] left-[-10rem] bg-[#FFDB57]/40 fixed rounded-full blur-[10rem]"></div>
      <div className="w-[30rem] h-[30rem] top-[-10rem] z-[-1] left-[-10rem] bg-[#FF8000]/20 fixed rounded-full blur-[10rem]"></div>
      <div className="w-[90vw] flex flex-col md:flex-row gap-[1rem] md:gap-[3rem] justify-center items-start">
        <div className="w-[15rem] p-3 bg-white hidden md:flex flex-col justify-start rounded-[10px] shadow-md">
          {navOptions.map((e, i) => (
            <section
              className={`px-2 py-3 hover:bg-orange-200/30 cursor-pointer rounded-[5px] text-slate-700 font-medium`}
              onClick={() => setPage(e)}
            >
              <p
                className={`${
                  page === e ? "text-orange-500 font-semibold" : ""
                }`}
              >
                {e}
              </p>
            </section>
          ))}
        </div>
        <div className="flex w-full justify-between bg-white text-slate-500 text-[14px] md:hidden">
          {navOptions?.map((option, i) => (
            <section className={`px-2 py-2 cursor-pointer font-semibold ${page === option ? "bg-orange-500 text-white" : "text-slate-500"}`} onClick={() => setPage(option)}>
              {option}
            </section>
          ))}
        </div>
        <div className="w-full">
          {page === "My Profile" ? (
            <Profile setReloadDIs={setReloadDis} />
          ) : page === "Address Details" ? (
            <Address setReloadDis={setReloadDis} />
          ) : page === "My Coupons" ? (
            <Coupons />
          ) : page === "My Orders" ? (
            <Orders />
          ) : page === "Subscriptions" ? (
            <Subscriptions />
          ) : (
            <Rewards />
          )}
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
