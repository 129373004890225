import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../component/Heading";
import { axiosInstance, SERVER_URL } from "../../constant";
import NotFound from "../../component/NotFound";
import DialogBox from "../../component/DialogBox";
import ButtonCompo from "../../component/ButtonCompo";
import { MdAddBox } from "react-icons/md";
import AlertBox from "../../component/AlertBox";
import Loading from "../../component/Loading";
import InputFieldsWithIcon from "../../component/InputFieldsWithIcon";
import { MdCloudUpload } from "react-icons/md";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import PaginationBar from "../../component/PaginationBar";

function STCatagory() {
  // navigate to home if superadmin is not logged
  const isStaffAuthenticated = useSelector(
    (state) => state.staffSlice?.isStaffAuthenticated
  );
  const staffData = useSelector((state) => state.staffSlice?.staffData);

  const navigate = useNavigate();
  useEffect(() => {
    if (isStaffAuthenticated !== undefined && isStaffAuthenticated === false) {
      navigate("/");
    }
  }, [isStaffAuthenticated]);

  //-------------------------------------------------------------------
  //---------------------------Main Part-------------------------------
  //-------------------------------------------------------------------

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [catagoryData, setCatagoryData] = useState([]);
  const [catagoryName, setCatagoryName] = useState("");
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const [reload, setReload] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  // get catagory
  async function getCatagories() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/catagory/get/all/${page}/50`
      );
      setCatagoryData(res.data?.data?.data);
      setTotalPages(res.data?.data?.totalPages);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // add catagory
  async function addCatagory() {
    if (catagoryName === "" || file === null) {
      setOpen(true);
      setSuccess(false);
      setMessage("Name and image is required");
      return null;
    }
    const formData = new FormData();
    formData.append("name", catagoryName);
    formData.append("catagoryImg", file);
    try {
      setLoading(true);
      const res = axiosInstance.post("/api/v1/product/catagory/add", formData);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Catagory added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // update catagory
  async function updateCatagory(catagoryId) {
    const formData = new FormData();
    formData.append("catagoryId", catagoryId);
    formData.append("name", catagoryName);
    if (file !== null) formData.append("catagoryImg", file);
    try {
      setLoading(true);
      const res = axiosInstance.put("/api/v1/product/catagory/edit", formData);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Catagory updated successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // delete catagory
  async function deleteCatagory(catagoryId) {
    try {
      setLoading(true);
      const res = await axiosInstance.delete(
        `/api/v1/product/catagory/delete/${catagoryId}`
      );
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Catagory deleted successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Something went wrong");
      console.log(error);
    } finally {
      setFile(null);
      setLoading(false);
    }
  }

  // set value
  function setValue(data) {
    setCatagoryName(data?.name);
  }

  useEffect(() => {
    getCatagories();
  }, [reload, page]);

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw]">
          <Heading>Catagories</Heading>
          <div className="w-full mt-5">
            <div className="w-full flex justify-end gap-3">
              <ButtonCompo
                variant={"outline"}
                startIcon={<IoMdRefresh />}
                onClick={() => setReload((prev) => !prev)}
              >
                Refresh
              </ButtonCompo>
              <DialogBox
                trigger={
                  <ButtonCompo variant={"primary"} startIcon={<MdAddBox />}>
                    New Catagory
                  </ButtonCompo>
                }
                title="Add New Catagory"
                actionButton={
                  <ButtonCompo onClick={addCatagory}>Save</ButtonCompo>
                }
              >
                <div>
                  <input
                    type="file"
                    className="hidden"
                    onChange={(event) => setFile(event?.target?.files[0])}
                    ref={fileRef}
                    accept=".png"
                  />
                  <InputFieldsWithIcon
                    label="Catagory Name"
                    onChange={(event) => setCatagoryName(event?.target?.value)}
                  />
                  <section
                    onClick={() => fileRef?.current?.click()}
                    className="flex w-full justify-between items-center border-slate-300 border rounded-[5px] mt-3 py-4 px-3 cursor-pointer hover:border-[#FF8000]"
                  >
                    <p className="text-slate-700 font-medium">
                      {file === null ? "Upload A Image" : "1 file selcted"}
                    </p>
                    <MdCloudUpload size={"25px"} className="text-[#FF8000]" />
                  </section>
                </div>
              </DialogBox>
            </div>
            {catagoryData?.length === 0 ? (
              <div className="w-full mt-[5rem]">
                <NotFound />
              </div>
            ) : (
              <div className="w-full">
                <div className="w-full mt-5">
                  <Table>
                    <TableHead>
                      <TableRow className="bg-[#FF8000]">
                        <TableCell className="!text-white !text-center !font-semibold">
                          Serial No.
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Image
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Catagory
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Edit
                        </TableCell>
                        <TableCell className="!text-white !text-center !font-semibold">
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="bg-white shadow-sm">
                      {catagoryData?.map((e, i) => (
                        <TableRow>
                          <TableCell className="!text-center !font-bold">
                            {Number(i) + 1}
                          </TableCell>
                          <TableCell className="!flex !justify-center !items-center">
                            <Link
                              className="w-full !flex !justify-center !items-center"
                              to={`${SERVER_URL}/api/v1/file/get/${e?.image}`}
                              target="_blank"
                            >
                              <section className="w-[3rem] h-[3rem]">
                                <img
                                  src={`${SERVER_URL}/api/v1/file/get/${e?.image}`}
                                  className="w-full h-full object-cover"
                                  alt=""
                                />
                              </section>
                            </Link>
                          </TableCell>
                          <TableCell className="!text-center">
                            {e?.name}
                          </TableCell>
                          <TableCell className="!text-center">
                            <DialogBox
                              trigger={
                                <ButtonCompo onClick={() => setValue(e)}>
                                  Edit
                                </ButtonCompo>
                              }
                              title="Edit Catagory"
                              actionButton={
                                <ButtonCompo
                                  onClick={() => updateCatagory(e?._id)}
                                >
                                  Save
                                </ButtonCompo>
                              }
                            >
                              <div>
                                <input
                                  type="file"
                                  className="hidden"
                                  onChange={(event) =>
                                    setFile(event?.target?.files[0])
                                  }
                                  ref={fileRef}
                                  accept=".png"
                                />
                                <InputFieldsWithIcon
                                  label="Catagory Name"
                                  value={catagoryName}
                                  onChange={(event) =>
                                    setCatagoryName(event?.target?.value)
                                  }
                                />
                                <section
                                  onClick={() => fileRef?.current?.click()}
                                  className="flex w-full justify-between items-center border-slate-300 border rounded-[5px] mt-3 py-4 px-3 cursor-pointer hover:border-[#FF8000]"
                                >
                                  <p className="text-slate-700 font-medium">
                                    {file === null
                                      ? "Upload A Image"
                                      : "1 file selcted"}
                                  </p>
                                  <MdCloudUpload
                                    size={"25px"}
                                    className="text-[#FF8000]"
                                  />
                                </section>
                              </div>
                            </DialogBox>
                          </TableCell>
                          <TableCell className="!text-center">
                            <ButtonCompo
                              variant={"destructive"}
                              onClick={() => deleteCatagory(e?._id)}
                            >
                              Delete
                            </ButtonCompo>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}
          </div>
          <div>
            <PaginationBar
              totalTablePage={totalPages}
              setTablePage={setPage}
              tablePage={page}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default STCatagory;
