import React from "react";
import head_img from "../assets/about-us/head.jpg";
import image1 from "../assets/about-us/image1.jpg";
import image2 from "../assets/about-us/image2.jpg";
import image3 from "../assets/about-us/image3.jpg";
import image4 from "../assets/about-us/image4.jpg";
import image5 from "../assets/about-us/image5.jpg";
import image6 from "../assets/about-us/image6.jpg";
import image7 from "../assets/about-us/image7.jpg";

function AboutUs() {
  return (
    <div className="w-full flex justify-center items-center flex-col">
      <div className="w-full flex justify-center items-center flex-col">
        <div
          className="w-full h-[35rem] bg-cover flex justify-center items-center px-5"
          style={{
            backgroundImage: `url("${head_img}")`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <section className="text-center">
            <h1 className="text-center heading_text uppercase text-[40px] font-extrabold tracking-wide text-[#fff]">
              About Us
            </h1>
            <p className="text-white text-[18px]">
              Fuel your curiosity for plant-based nutrition, stress management,
              self-care, and so much more
            </p>
          </section>
        </div>
        <div className="w-[90vw] flex justify-center items-center flex-col">
          <div className="w-full grid grid-cols-4 place-items-center mt-[-10rem] gap-3">
            <img
              src={image1}
              className="w-[25rem] border-white border-2 rounded-[7px]"
              alt=""
            />
            <img
              src={image2}
              className="w-[25rem] border-white border-2 rounded-[7px]"
              alt=""
            />
            <img
              src={image4}
              className="w-[25rem] border-white border-2 rounded-[7px]"
              alt=""
            />
            <img
              src={image3}
              className="w-[25rem] border-white border-2 rounded-[7px]"
              alt=""
            />
          </div>
          <div className="w-full mt-[5rem]">
            <section className="w-full text-left">
              <p className="heading_text text-[40px]">
                We make sure our customer Happy and Healthy :{" "}
                <span className="text-[#ff8000] heading_text">NBS</span>
              </p>
            </section>
            <section className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-[2rem] text-[17px] text-slate-500">
              <p>
                We are Health professionals and have been rendering our services
                in diagnosing and treating disease for more than two decades. We
                have seen a paradigm shift in lifestyle, there has been an
                increase in non-communicable diseases (NCDs) like heart disease,
                diabetes, and obesity. A sedentary lifestyle and IT culture lead
              </p>
              <p>
                We are Health professionals and have been rendering our services
                in diagnosing and treating disease for more than two decades. We
                have ices in diagnosing and treating disease for more than two
                decades. We have ices in diagnosing and treating disease for
                more than two decades.
              </p>
            </section>
          </div>
          <div className="w-full mt-[3rem]">
            <div className="flex flex-col md:flex-row justify-between items-center w-full gap-5">
              <section className="w-[100%] md:w-[50%] relative flex flex-col justify-center items-center">
                <img
                  src={image5}
                  className="w-full h-full object-cover"
                  alt=""
                />
                <section className="absolute bottom-[-2rem] md:bottom-[-3rem] flex border-[1px] border-white rounded-[10px] bg-slate-600/30 backdrop-blur-[7px]">
                  <div className="p-2 md:p-10 text-white rounded-[10px] text-center">
                    <p className="text-[20px] md:text-[30px] font-bold">2020</p>
                    <span className="font-medium text-[12px] md:text-[16px]">
                      Founding Year
                    </span>
                  </div>
                  <div className="p-2 md:p-10 text-white rounded-[10px] text-center">
                    <p className="text-[20px] md:text-[30px] font-bold">
                      30000
                    </p>
                    <span className="font-medium text-[12px] md:text-[16px]">
                      Happy Customers
                    </span>
                  </div>
                  <div className="p-2 md:p-10 text-white rounded-[10px] text-center">
                    <p className="text-[20px] md:text-[30px] font-bold">75</p>
                    <span className="font-medium text-[12px] md:text-[16px]">
                      Health Camps
                    </span>
                  </div>
                </section>
              </section>
              <section className="w-[100%] md:w-[40%]">
                <h2 className="heading_text text-[40px]">
                  Your Health is Our Priorities
                </h2>
                <p className="text-slate-500">
                  We are Health professionals and have been rendering our
                  services in diagnosing and treating disease for more than two
                  decades. We have seen a paradigm shift in lifestyle, there has
                  been an increase in non-
                </p>
              </section>
            </div>
          </div>
          <div className="w-full mt-[5rem]">
            <div className="flex justify-between flex-col md:flex-row items-center w-full gap-5">
              <section className="w-[100%] md:w-[40%]">
                <h2 className="heading_text text-[40px]">
                  Our Product Quality is Our First Priorities
                </h2>
                <p className="text-slate-500">
                  We are Health professionals and have been rendering our
                  services in diagnosing and treating disease for more than two
                  decades. We have seen a paradigm shift in lifestyle, there has
                  been an increase in non-
                </p>
              </section>
              <section className="w-[100%] md:w-[50%] relative flex flex-col justify-center items-center">
                <img
                  src={image6}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </section>
            </div>
          </div>
          <div className="w-full mt-[5rem] flex flex-col justify-center items-center">
            <section className="text-center space-y-3">
              <h2 className="heading_text text-[40px]">
                Empowering lives through Nutritional wellness.
              </h2>
              <p className="text-slate-500">
                We are Health professionals and have been rendering our services
                in diagnosing and treating disease for more than two decades. We
                have seen a.
              </p>
            </section>
            <section className="w-[100%] relative flex flex-col justify-center items-center mt-[3rem]">
              <img src={image7} className="w-full h-full object-cover" alt="" />
              <section className="absolute bottom-[-2rem] md:bottom-[-3rem] flex border-[1px] border-white rounded-[10px] bg-slate-600/30 backdrop-blur-[7px]">
                <div className="p-2 md:p-10 text-white rounded-[10px] text-center">
                  <p className="text-[20px] md:text-[30px] font-bold">50+</p>
                  <span className="font-medium text-[12px] md:text-[16px]">
                    Cities
                  </span>
                </div>
                <div className="p-2 md:p-10 text-white rounded-[10px] text-center">
                  <p className="text-[20px] md:text-[30px] font-bold">110+</p>
                  <span className="font-medium text-[12px] md:text-[16px]">
                    Team Members
                  </span>
                </div>
                <div className="p-2 md:p-10 text-white rounded-[10px] text-center">
                  <p className="text-[20px] md:text-[30px] font-bold">5+</p>
                  <span className="font-medium text-[12px] md:text-[16px]">
                    Warehouse
                  </span>
                </div>
              </section>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
