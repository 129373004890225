import React from "react";
import logo from "../assets/companyLogo.png";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import whatsapp from "../assets/whatsapp.svg";
import twitter from "../assets/twitter.svg";
import youtube from "../assets/youtube.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="w-full flex justify-center flex-col items-center footer-bg mt-[2rem] py-[2rem] pb-[4rem] lg:pb-[1rem]">
      <div className="w-[90vw]">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[3rem] lg:place-items-center">
          <section className="space-y-[1rem] h-full">
            <img src={logo} alt="" />
            <p className="heading_text text-[20px] font-medium">
              "Nutri Bio Science offers natural, plant-based healthcare products
              crafted to enhance lifestyle.
            </p>
            <section className="flex items-center gap-3">
              <Link to={"https://www.facebook.com/nutribiosciences"} target="_blank">
                <img src={facebook} className="w-[2rem]" alt="" />
              </Link>
              <Link to={"https://www.instagram.com/nutri_biosciences?igsh=Nmd0dWh5OHhqaHJh"} target="_blank">
                <img src={instagram} className="w-[2rem]" alt="" />
              </Link>
              <Link to={"https://www.youtube.com/@NutriBiosciences"} target="_blank">
                <img src={youtube} className="w-[2rem]" alt="" />
              </Link>
              <Link to={"https://twitter.com/nbsonline11"} target="_blank">
                <img src={twitter} className="w-[2rem]" alt="" />
              </Link>
            </section>
          </section>
          <section className="flex flex-col gap-2 h-full">
            <Link to={"/shop"}>Shop</Link>
            <Link to={"/profile"}>My Account</Link>
            <Link to={"/cart"}>Cart</Link>
          </section>
          <section className="flex flex-col gap-2 h-full">
            <Link to={"/about-us"}>About Us</Link>
            <Link to={"/contact-us"}>Contact Us</Link>
            <Link to={"/terms-and-conditions"}>Terms & Condition</Link>
            <Link to={"/privacy-and-policy"}>Privacy Policy</Link>
            <Link to={"/refund-and-returns"}>Refund & Returns</Link>
            <Link to={"/shipping-and-delivery"}>Shipping & Delivery</Link>
          </section>
        </div>
        <div className="w-full h-[1px] bg-orange-700 mt-[2rem]"></div>
      </div>
    </div>
  );
}

export default Footer;
