import React from 'react'

function HealthTips() {
  return (
    <div>
      Health Tips
    </div>
  )
}

export default HealthTips
