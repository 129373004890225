import React from "react";
import logo from "../../assets/companyLogo.png";
import { FaUserPlus } from "react-icons/fa";
import DrawerCompo from "../DrawerCompo";
import { MdDashboard } from "react-icons/md";
import { IoSettingsSharp } from "react-icons/io5";

function SAHeader() {
  const dataArray = [
    {
      heading: "Home",
      content: [
        {
          title: <p className="text-orange-500/90 font-bold">Dashboard</p>,
          icon: <MdDashboard size={"22px"} className="text-orange-400" />,
          link: "/superadmin/dashboard",
        },
      ],
    },
    {
      heading: "Company",
      content: [
        {
          title: "Company Settings",
          icon: <IoSettingsSharp size={"22px"} className="text-slate-800/60" />,
          link: "/superadmin/company/settings",
        },
        {
          title: "Add Staff",
          icon: <FaUserPlus size={"22px"} className="text-slate-800/60" />,
          link: "/superadmin/staff/add",
        },
      ],
    },
  ];

  return (
    <header className="w-full px-5 py-3 fixed top-0 flex justify-between items-center">
      <DrawerCompo dataArray={dataArray} heading={"SUPERADMIN"} />
      <img src={logo} className="w-[9rem]" alt="" />
    </header>
  );
}

export default SAHeader;
