import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance, SERVER_URL } from "../constant";
import logo from "../assets/companyLogo.png";
import { Rating } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ButtonCompo from "../component/ButtonCompo";
import NotFound from "../component/NotFound";
import DialogBox from "../component/DialogBox";
import RatingCompo from "../component/RatingCompo";
import InputFieldsWithIcon from "../component/InputFieldsWithIcon";
import AlertBox from "../component/AlertBox";
import Loading from "../component/Loading";
import { useSelector } from "react-redux";
import AddToCart from "../component/AddToCart";

function ProductDetails() {
  const { productId } = useParams();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [allImages, setAllImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [productData, setProductData] = useState({});
  const [rating, setRating] = useState(0);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [allVariant, setAllVariant] = useState([]);
  const [enableTruncate, setEnableTruncate] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [selectedRating, setSelectedRating] = useState(0);
  const [selectedReview, setSelectedReview] = useState("");
  const isAuthenticatedUser = useSelector(
    (state) => state.auth?.isAuthenticated
  );

  // get product
  async function getProduct() {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/api/v1/product/get/single/${productId}`
      );
      const rawData = res.data?.data;
      setProductData(rawData);
      setRating(rawData?.avgRating);
      if (Array.isArray(rawData?.images)) {
        setAllImages(rawData?.images);
        setSelectedImage(rawData?.images[0]);
      }
      if (Array.isArray(rawData?.variant)) {
        setAllVariant(rawData?.variant);
        setSelectedVariant(rawData?.variant[0]);
      }
      // console.log(rawData);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  // get reviews
  async function getReviews() {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/api/v1/product/review/get/all/${productId}/1`
      );
      console.log(res.data?.data?.data);
      setReviews(res.data?.data?.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  // add review
  async function addReview() {
    if (selectedRating === 0 || selectedReview === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Please give rating and review both");
      return null;
    }
    const data = {
      rating: selectedRating,
      comment: selectedReview,
      productId: productId,
      date: new Date().toLocaleDateString(),
    };
    try {
      setLoading(true);
      const res = await axiosInstance.post(`/api/v1/product/review/add`, data);
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Review has been sent successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getProduct();
    getReviews();
  }, [reload]);

  if (loading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <img src={logo} className="w-[10rem]" alt="" />
      </div>
    );
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[90vw] mt-[10rem]">
          <div className="w-full flex flex-col lg:flex-row justify-between items-start gap-[2rem]">
            <div className="w-[90vw] lg:w-[30rem]">
              <div className="w-full flex justify-center flex-col">
                <section className="bg-white border-[2px] rounded-[10px] border-[#FF8000] flex justify-center items-center h-[30rem] relative">
                  <img
                    src={`${SERVER_URL}/api/v1/file/get/${selectedImage}`}
                    alt="absolute"
                  />
                </section>
              </div>
              <div className="mt-5 flex gap-3">
                {allImages?.map((e, i) => (
                  <section
                    className={`w-[5rem] bg-white h-[5rem] border rounded-[10px] cursor-pointer hover:border-orange-500 ${
                      e === selectedImage
                        ? "border-[#ff8000] border-[1.7px]"
                        : ""
                    }`}
                    onClick={() => setSelectedImage(e)}
                  >
                    <img src={`${SERVER_URL}/api/v1/file/get/${e}`} alt="" />
                  </section>
                ))}
              </div>
            </div>
            <div className="w-full">
              <section>
                <p className="text-[30px] font-bold text-orange-800">
                  {productData?.title}
                </p>
                <p className="mt-3 text-slate-600">{productData?.subTitle}</p>
                <section className="mt-3 flex gap-2 items-center">
                  <Rating name="read-only" value={rating} readOnly />
                  <p className="font-semibold">
                    {productData?.avgRating}{" "}
                    <span className="font-medium text-slate-500">
                      ({productData?.totalReview} reviews)
                    </span>
                  </p>
                </section>
                <section className="mt-2">
                  <p>
                    Quantity :{" "}
                    <span>
                      {selectedVariant?.value} {selectedVariant?.unit}
                    </span>
                  </p>
                  <p className="text-[30px] font-bold text-slate-800 mt-3">
                    ₹
                    {selectedVariant?.specialPrice === 0
                      ? selectedVariant?.sellingPrice
                      : selectedVariant?.specialPrice}
                    {selectedVariant?.sellingPrice !==
                    selectedVariant?.mrp ? (
                      <span className="ml-3 line-through text-slate-500 text-[16px]">
                        ₹{selectedVariant?.mrp}
                      </span>
                    ) : (
                      ""
                    )}
                    {selectedVariant?.discount > 0 ? (
                      <span className="text-green-600 font-semibold ml-5 text-[25px]">
                        ({selectedVariant?.discount}% off)
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                  <section className="flex items-center mt-[1rem] sm:mt-[3rem] w-fit gap-3">
                    {allVariant?.map((variant) => (
                      <section
                        className={`p-3 rounded-[10px] space-y-1 cursor-pointer hover:bg-orange-100/70 border hover:border-orange-500 ${
                          variant?.value === selectedVariant?.value &&
                          variant?.unit === selectedVariant?.unit
                            ? "border-orange-500 border-[2px]"
                            : ""
                        }`}
                        onClick={() => setSelectedVariant(variant)}
                      >
                        <p className="font-medium">
                          Quantity:{" "}
                          <span>
                            {variant?.value} {variant?.unit}
                          </span>
                        </p>
                        <p className="font-medium">
                          Price:{" "}
                          <span>
                            ₹
                            {variant?.specialPrice === 0
                              ? variant?.sellingPrice
                              : variant?.specialPrice}
                          </span>
                        </p>
                      </section>
                    ))}
                  </section>
                  <section className="flex gap-3 mt-[2rem] sm:mt-[3rem] flex-col md:flex-row">
                    <AddToCart
                      productId={productData?._id}
                      productQuantity={`${selectedVariant?.value} ${selectedVariant?.unit}`}
                      discount={selectedVariant?.discount}
                      productImg={selectedImage}
                      productTitle={productData?.title}
                      productVariant={selectedVariant}
                      totalPrice={
                        selectedVariant?.specialPrice === 0
                          ? selectedVariant?.sellingPrice
                          : selectedVariant?.specialPrice
                      }
                      productCatagory={productData?.catagory}
                      className="!w-[100%] md:!w-[15rem] !bg-[#FF8000] !px-10 !py-3 !text-white !font-semibold"
                    />
                    <AddToCart
                      className="!w-[100%] md:!w-[15rem] !text-[#FF8000] !px-10 !py-3 !font-semibold"
                      sx={{
                        border: "1px solid #FF8000",
                      }}
                      productId={productData?._id}
                      productQuantity={`${selectedVariant?.value} ${selectedVariant?.unit}`}
                      discount={selectedVariant?.discount}
                      productImg={selectedImage}
                      productTitle={productData?.title}
                      productVariant={selectedVariant}
                      totalPrice={
                        selectedVariant?.specialPrice === 0
                          ? selectedVariant?.sellingPrice
                          : selectedVariant?.specialPrice
                      }
                      productCatagory={productData?.catagory}
                      isBuyNow={true}
                    />
                  </section>
                </section>
              </section>
            </div>
          </div>
          <section className="mt-[3rem] border w-[100%] sm:w-fit p-5 rounded-[10px] border-orange-300 shadow-orange-300">
            {productData?.mktBy && (
              <p className="flex flex-col justify-start text-slate-500">
                Manufacturer By{" "}
                <span className="text-slate-700 font-semibold">
                  {productData?.mktBy}
                </span>
              </p>
            )}
            {productData?.mktDate && (
              <p className="flex flex-col justify-start text-slate-500">
                Manufacture Date{" "}
                <span className="text-slate-700 font-semibold">
                  {productData?.mktDate}
                </span>
              </p>
            )}
            {productData?.origin && (
              <p className="flex flex-col justify-start text-slate-500">
                Origin{" "}
                <span className="text-slate-700 font-semibold">
                  {productData?.origin}
                </span>
              </p>
            )}
            {productData?.expDate && (
              <p className="flex flex-col justify-start text-slate-500">
                Expiry{" "}
                <span className="text-slate-700 font-semibold">
                  {productData?.expDate}
                </span>
              </p>
            )}
          </section>
          <div className="w-full mt-[3rem]">
            <p className="font-semibold text-[16px]">Product Details</p>
            <section
              className="hover:bg-orange-100/30 cursor-pointer"
              onClick={() => setEnableTruncate((prev) => !prev)}
            >
              {enableTruncate === true ? (
                <p className={`mt-3 text-slate-600`}>
                  {`${productData?.description?.slice(0, 200)}...`}
                </p>
              ) : (
                <p
                  className={`mt-3 text-slate-600`}
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {productData?.description}
                </p>
              )}
              <span className="font-semibold text-orange-500">
                {enableTruncate ? "See More" : "See Less"}
              </span>
            </section>
          </div>
          <div className="mt-5">
            <section className="flex items-center gap-5">
              <p className="text-[20px] font-semibold">
                Reviews{" "}
                <span className="text-slate-500">
                  ({productData?.totalReview} reviews)
                </span>
              </p>
              <DialogBox
                trigger={
                  isAuthenticatedUser === true && (
                    <ButtonCompo className="!bg-[#FF8000] !rounded-full !text-[13px] !px-5 !text-white !font-semibold">
                      Add A Review
                    </ButtonCompo>
                  )
                }
                title="Give A Review"
                actionButton={
                  <ButtonCompo onClick={addReview}>Save</ButtonCompo>
                }
              >
                <div className="w-full">
                  <div className="flex flex-col justify-center items-center gap-2">
                    <label className="font-medium">Please rate us</label>
                    <RatingCompo
                      setValue={(event) => setSelectedRating(event)}
                    />
                  </div>
                  <InputFieldsWithIcon
                    label="Review"
                    multiline
                    onChange={(event) =>
                      setSelectedReview(event?.target?.value)
                    }
                  />
                </div>
              </DialogBox>
            </section>
            <div className="mt-5">
              <div className="w-[100%] sm:w-[60%]">
                {reviews?.length === 0 ? (
                  <div>
                    <NotFound />
                  </div>
                ) : (
                  <div className="w-full space-y-5">
                    {reviews?.map((review, i) => (
                      <section className="w-full flex items-start gap-3 border-b-[1px] pb-5">
                        <section className="">
                          <div className="w-[2.5rem] h-[2.5rem] bg-orange-200 rounded-full">
                            <img
                              src={`${SERVER_URL}/api/v1/file/get/${review?.userImg}`}
                              className="w-full h-full object-cover rounded-full"
                              alt=""
                            />
                          </div>
                        </section>
                        <section>
                          <p className="font-semibold mb-[1px]">
                            {review?.username}
                          </p>
                          <Rating
                            value={review?.rating}
                            size="small"
                            readOnly
                          />
                          <p className="mt-1 text-slate-500">
                            {review?.comment}
                          </p>
                        </section>
                      </section>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetails;
