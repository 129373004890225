import React, { useEffect } from "react";
import Heading from "../component/Heading";

function PrivacyAndPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full flex justify-center items-center mt-[7rem]">
      <div className="w-[90vw]">
        <Heading>Privacy & Policy</Heading>
        <div className="w-full mt-[3rem] space-y-5">
          <p>
            Thank you for visiting&nbsp;
            <a href="https://www.nutribsc.com/" target="_new">
              nutribsc.com
            </a>
            . Nutri Biosciences (referred to as “we,” “us,” or “our”) values
            your privacy and is committed to protecting your personal
            information. This Privacy Policy outlines our practices regarding
            the collection, use, and sharing of your personal data when you use
            our Website.
          </p>
          <p>
            <strong>Information We Collect</strong>
          </p>
          <p>
            When you visit our Website, we may collect the following types of
            information:
          </p>
          <ol>
            <li>
              <strong>Personal Information:</strong>&nbsp;We may collect
              personal information, such as your name, email address, phone
              number, and other contact details, when you voluntarily provide it
              to us through forms or when contacting us via email.
            </li>
            <li>
              <strong>Usage Information:</strong>&nbsp;We may collect
              non-personal information about your interactions with our Website,
              such as your IP address, browser type, device type, and browsing
              patterns. This information helps us improve our services and
              enhance your experience.
            </li>
          </ol>
          <p>
            <strong>How We Use Your Information</strong>
          </p>
          <p>We use the information we collect for the following purposes:</p>
          <ol>
            <li>
              <strong>To Provide Services:</strong>&nbsp;We may use your
              personal information to respond to your inquiries, provide
              information about our products and services, and fulfill your
              requests.
            </li>
            <li>
              <strong>To Improve Our Website:</strong>&nbsp;We analyze usage
              data to understand how visitors use our Website, identify areas
              for improvement, and optimize the user experience.
            </li>
            <li>
              <strong>To Send Promotional Communications:</strong>&nbsp;With
              your consent, we may send you marketing and promotional
              communications about our products, services, and special offers.
              You can opt-out of these communications at any time by following
              the provided instructions.
            </li>
          </ol>
          <p>
            <strong>Cookies and Tracking Technologies</strong>
          </p>
          <p>
            Our Website may use cookies and similar tracking technologies to
            enhance user experience and collect information about your browsing
            habits. You can manage your cookie preferences through your browser
            settings.
          </p>
          <p>
            <strong>Third-Party Links</strong>
          </p>
          <p>
            Our Website may contain links to third-party websites or services.
            We are not responsible for the privacy practices or content of these
            third-party sites. We recommend reviewing the privacy policies of
            any third-party websites you visit.
          </p>
          <p>
            <strong>Data Security</strong>
          </p>
          <p>
            We employ industry-standard security measures to protect your
            personal information from unauthorized access, disclosure,
            alteration, and destruction.
          </p>
          <p>
            <strong>Your Rights</strong>
          </p>
          <p>You have the right to:</p>
          <ul>
            <li>Access and request a copy of your personal information.</li>
            <li>Correct inaccuracies in your personal information.</li>
            <li>Withdraw consent for marketing communications.</li>
            <li>
              Delete your personal information (subject to legal obligations).
            </li>
          </ul>
          <p>
            To exercise these rights or if you have any questions about our
            Privacy Policy, please contact us at Support@nutribsc.com
          </p>
          <p>
            <strong>Changes to this Privacy Policy</strong>
          </p>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. The revised Privacy Policy will be effective
            upon posting on the Website with the updated effective date.
          </p>
          <p>
            By continuing to use our Website after the Privacy Policy has been
            updated, you consent to the changes.
          </p>
          <p>
            <strong>Contact Information</strong>
          </p>
          <p>
            If you have any questions or concerns about our Privacy Policy or
            data practices, please contact us at Support@nutribsc.com
          </p>
          <p>
            Thank you for entrusting Nutri Biosciences with your privacy. We are
            committed to protecting your personal information and providing a
            safe online experience.
          </p>
          <p>&nbsp;</p>
          <p>
            <span>
              DISCLAIMER: All statements are based on the clinically studied
              benefits of each ingredient. This product is not intended to
              diagnose, treat, cure, or prevent any disease.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyAndPolicy;
