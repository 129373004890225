import React from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../constant";
import StarIcon from "@mui/icons-material/Star";
import AddToCart from "./AddToCart";

function ProductCard({ productData }) {
  return (
    <div className="space-y-5 w-[95%] md:w-[15rem] xl:w-[15rem] 2xl:w-[17rem] border rounded-[10px] border-[#ff80004f] mb-[3rem] card-shadow-hover">
      <Link to={`/product/${productData?._id}`}>
        <section
          className={`bg-orange-200/20 2xl:max-w-[17rem] flex-col h-[12rem] xl:h-[15rem] rounded-t-[10px] overflow-visible flex justify-center items-center relative`}
        >
          <section className="absolute top-0 right-0 px-3 py-1">
            <section className="bg-[#FF8000] text-[13px] px-3 py-[2px] text-white font-medium rounded-[5px] mt-1">
              {productData?.catagory}
            </section>
          </section>
          <img
            src={`${SERVER_URL}/api/v1/file/get/${productData?.images[0]}`}
            className="w-[15rem] h-auto object-cover"
            alt=""
            loading="lazy"
          />
        </section>
      </Link>
      <section className="w-full p-3">
        <section className="flex w-full justify-between">
          <p className="text-orange-800 font-semibold text-[16px] truncate">
            {productData?.title}
          </p>
          <p className="flex items-center justify-center bg-white rounded-[5px] text-[14px] text-orange-700 font-medium border px-2 gap-1">
            <StarIcon className="text-yellow-400 !text-[17px]" />{" "}
            {productData?.avgRating}
          </p>
        </section>
        <section className="w-full mt-1">
          <p className="truncate">{productData?.subTitle}</p>
        </section>
        <section>
          <p className="space-x-3 mt-1 text-[18px]">
            ₹
            {productData?.variant[0]?.specialPrice === 0
              ? productData?.variant[0]?.sellingPrice
              : productData?.variant[0]?.specialPrice}
            {productData?.variant[0]?.sellingPrice !==
            productData?.variant[0]?.mrp ? (
              <span className="ml-3 line-through text-slate-500 text-[16px]">
                ₹{productData?.variant[0]?.mrp}
              </span>
            ) : (
              ""
            )}
            {productData?.variant[0]?.discount > 0 ? (
              <span className="text-green-600 font-semibold">
                ({productData?.variant[0]?.discount}% off)
              </span>
            ) : (
              ""
            )}
          </p>
        </section>
        <section className="flex flex-col gap-3 mt-5">
          <AddToCart
            productId={productData?._id}
            productQuantity={`${productData?.variant[0]?.value} ${productData?.variant[0]?.unit}`}
            discount={productData?.variant[0]?.discount}
            productImg={productData?.images[0]}
            productTitle={productData?.title}
            productVariant={productData?.variant[0]}
            totalPrice={
              productData?.variant[0]?.specialPrice === 0
                ? productData?.variant[0]?.sellingPrice
                : productData?.variant[0]?.specialPrice
            }
            productCatagory={productData?.catagory}
          />
        </section>
      </section>
    </div>
  );
}

export default ProductCard;
